import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import PlanDialog from '../components/PlanDialog';
import PlanList from '../components/PlanList';
import PlanDetail from '../components/PlanDetail'
import { getPlans, getPlan, deletePlan, cleanPlan } from '../redux/actions/plan';
import { getPlanTypes } from '../redux/actions/plan_type';
import { getObjectiveTypes } from '../redux/actions/objective_type';
import { getObjectiveGroups } from '../redux/actions/objective_group';
import { getActivityTypes } from '../redux/actions/activity_type';
import { getTaskTypes } from '../redux/actions/task_type';
import { getTaskNatures } from '../redux/actions/task_nature';
import { getOrgs } from '../redux/actions/org';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '../components/SnackBarContent';

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    overflow: 'hidden',
    marginTop: 15,
    marginBottom: 15
  },
  grid: {
    display: 'flex',
    height: '100%',
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  list: {
    overflowY: 'scroll',
    height: '100%',
    width: '100%'
  },
  text: {
    padding: 10,
    margin: 0,
    width: '100%'
  },
  planTitle: {
    lineHeight: '36px',
    verticalAlign: 'middle'
  },
  breadcrumb: {
    paddingBottom: 20
  },
  breadcrumbLink: {
    fontSize: '1rem',
    color: '#3f51b5'
  }
});

class Plan extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      currentPlan: null,
      addModal: false,
      planId: null,
      showError: false
    }
    
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.changePlan = this.changePlan.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
  }

  componentDidMount() {
    document.title = "Kế hoạch";

    const { match: { params } } = this.props;
    
    this.changePlan(params.id);
    this.props.getPlanTypes();
    this.props.getOrgs();
    this.props.getObjectiveTypes();
    this.props.getObjectiveGroups();
    this.props.getActivityTypes();
    this.props.getTaskTypes();
    this.props.getTaskNatures();
  }

  changePlan(id) {
    const { plansFetching } = this.props;
    if(!id)
    {
      this.props.getPlans(1);
      return;
    }

    if(id) {
      this.setState({ 
        planId: id
      }, () => {
        this.props.getPlan(id);
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { match: { params } } = this.props;

    if(prevProps.objectiveCreated == null && this.props.objectiveCreated == true) {
      this.props.getPlan(this.state.planId);
      this.setState({ 
        addModal: false
      })
    }

    if(prevProps.created == null) {
      if(this.props.created == true) {
        this.setState({ 
          addModal: false
        })
      }
      else if(this.props.created == false) {
        this.setState({
          showError: true
        });
      }
    }

    if(params.id !== undefined && params.id !== this.state.planId) {
      this.changePlan(params.id);
    }
  }


  toggleAddModal() {
    this.setState({addModal: !this.state.addModal})
  }

  handleCloseSnackbar() {
    this.setState({
      showError: false
    })
  }

  render() {
    const { classes, plans, currentPlan } = this.props;
    if (plans.length > 0) {
      if (!this.props.match.params.id) {
        var plan = plans[0]
        return <Redirect to={"/plan/" + plan.id} />
      }

      var checkPlan = plans.filter(p => {
        return p.id == this.props.match.params.id
      })[0]

      if (!checkPlan) {
        var plan = plans.filter(p => {
          return p.id != this.props.match.params.id
        })[0]
        return <Redirect to={"/plan/" + plan.id} />
      }
    }
    
    return (
      <div>
        <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumb}>
          <Link component={RouterLink} color="inherit" to="/" className={classes.breadcrumbLink}>
            S-Planning
          </Link>
          {currentPlan.id &&
            <Link component={RouterLink} color="inherit" to={"/plan/" + currentPlan.id} className={classes.breadcrumbLink}>
              Kế hoạch: {currentPlan.title}
            </Link>
            ||
          <Link component={RouterLink} color="inherit" to={"/plans"} className={classes.breadcrumbLink}>
            Kế hoạch
          </Link>
          }
          
          
        </Breadcrumbs>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.showError}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <SnackbarContent
            onClose={this.handleCloseSnackbar}
            variant="error"
            message={this.props.errors}
          />
        </Snackbar>
        <Grid container spacing={24} className={classes.grid}>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" onClick={this.toggleAddModal}>Thêm kế hoạch</Button>
            <Paper className={classes.paper} elevation={1}>
              <Typography variant="h5">Kế hoạch</Typography>
              <PlanList />
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h5" className={classes.planTitle}>
              { currentPlan ? currentPlan.title : '' }
            </Typography>
            <Paper className={classes.paper} elevation={1}>
              <PlanDetail data={currentPlan} refresh={() => this.changePlan(this.state.planId)} />
            </Paper>
          </Grid>
        </Grid>
        <PlanDialog open={this.state.addModal} toggle={this.toggleAddModal} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  plansFetching: state.plan.plansFetching,
  plans: state.plan.plans,
  currentPlan: state.plan.currentPlan,
  created: state.plan.created,
  deleted: state.plan.deleted,
  errors: state.plan.errors,
  objectiveCreated: state.objective.create.success,
});
const mapDispatchToProps = {
  getPlans, getPlan, deletePlan, cleanPlan, getOrgs,
  getPlanTypes, getObjectiveTypes, getObjectiveGroups, getActivityTypes, getTaskTypes,
  getTaskNatures
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Plan));