import { createRequestTypes } from './utils';

export const GET_OBJECTIVE_TYPES = createRequestTypes('GET_OBJECTIVE_TYPES');
export const CREATE_OBJECTIVE_TYPE = createRequestTypes('CREATE_OBJECTIVE_TYPE');
export const UPDATE_OBJECTIVE_TYPE = createRequestTypes('UPDATE_OBJECTIVE_TYPE');
export const GET_OBJECTIVE_TYPE = createRequestTypes('GET_OBJECTIVE_TYPE');
export const DELETE_OBJECTIVE_TYPE = createRequestTypes('DELETE_OBJECTIVE_TYPE');

export const CLEAN_OBJECTIVE_TYPE = 'CLEAN_OBJECTIVE_TYPE';
export const cleanObjectiveType = () => ({ type: CLEAN_OBJECTIVE_TYPE });

export const getObjectiveTypes = page => ({
  type: GET_OBJECTIVE_TYPES.REQUEST,
  page
});

export const createObjectiveType = data => ({
  type: CREATE_OBJECTIVE_TYPE.REQUEST,
  data
});

export const updateObjectiveType = (id, data) => ({
  type: UPDATE_OBJECTIVE_TYPE.REQUEST,
  id,
  data
});

export const getObjectiveType = id => ({
  type: GET_OBJECTIVE_TYPE.REQUEST,
  id
});

export const deleteObjectiveType = id => ({
  type: DELETE_OBJECTIVE_TYPE.REQUEST,
  id
});
