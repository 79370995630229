import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { createPlan } from '../redux/actions/plan';

const styles = theme => ({
  paper: {

  },
  input: {
    margin: theme.spacing.unit,
    width: '100%'
  },
  formControl: {
    marginBottom: theme.spacing.unit * 3,

  }
})

class FormDialog extends React.Component {
  state = {
    title: '',
    support: '',
    description: '',
    organisation: null,
    user: null,
    start_at: null,
    end_at: null,
    days: 10,
    type: null,
    status: 'planning'
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.organisation == null && this.props.orgs.length > 0) {
      this.setState({
        organisation: this.props.orgs[0].id
      })
    }
    if(this.state.type == null && this.props.types.length > 0) {
      this.setState({
        type: this.props.types[0].id
      })
    }
  }

  onSubmit = () => {
    this.props.createPlan(this.state)
  }
  render() {
    const { open, toggle, classes, orgs, types } = this.props
    return (
      <Dialog
        open={open}
        onClose={toggle}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Thêm kế hoạch</DialogTitle>
        <DialogContent>
          
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Tên kế hoạch"
            type="text"
            fullWidth
            value={this.state.title}
            onChange={ref => this.setState({ title: ref.target.value })}
          />
          <TextField
            margin="dense"
            id="description"
            label="Nội dung"
            multiline
            rows={3}
            type="text"
            fullWidth
            value={this.state.description}
            onChange={ref => this.setState({ description: ref.target.value })}
          />
          <TextField
            autoFocus
            margin="dense"
            id="user"
            label="Người chủ trì (nhập email)"
            type="text"
            fullWidth
            value={this.state.user}
            onChange={ref => this.setState({ user: ref.target.value })}
          />
          <TextField
            autoFocus
            margin="dense"
            id="support"
            label="Phối hợp"
            type="text"
            fullWidth
            value={this.state.support}
            onChange={ref => this.setState({ support: ref.target.value })}
          />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Đơn vị (bộ phận)</InputLabel>
            <NativeSelect
              value={this.state.organisation}
              onChange={ref => this.setState({ organisation: ref.target.value })}
            >
            {orgs && orgs.map(org => [
              <option  value={org.id}>{org.title}</option >
              ,
              org.children && org.children.map(child => 
                <option  value={child.id}>---- {child.title}</option >)
            ]
            )}
            </NativeSelect>
          </FormControl>
          {/* <TextField
            margin="dense"
            id="user"
            label="Chủ trì"
            multiline
            type="text"
            fullWidth
            value={this.state.host}
            onChange={ref => this.setState({ host: ref.target.value })}
          /> */}
          <TextField
            id="date"
            label="Thời gian bắt đầu"
            type="date"
            fullWidth
            
            className={classes.textField}
            onChange={ref => this.setState({ start_at: ref.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="days"
            label="Số ngày thực hiện"
            multiline
            type="text"
            fullWidth
            value={this.state.days}
            onChange={ref => this.setState({ days: ref.target.value })}
          />
          <TextField
            id="date"
            label="Thời gian kết thúc"
            type="date"
            fullWidth
            
            className={classes.textField}
            onChange={ref => this.setState({ end_at: ref.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Loại kế hoạch</InputLabel>
            <Select
              value={this.state.type}
              onChange={ref => this.setState({ type: ref.target.value })}
            >
            {types && types.map(type => 
              <MenuItem value={type.id}>{type.title}</MenuItem>
            )}
            </Select>
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Tình trạng</InputLabel>
            <Select
              value={this.state.status}
              onChange={ref => this.setState({ status: ref.target.value })}
            >
            <MenuItem value='planning'>Đang lập kế hoạch</MenuItem>
            <MenuItem value='doing'>Đang tiến hành</MenuItem>
            <MenuItem value='done'>Đã hoàn thành</MenuItem>
            </Select>
          </FormControl>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle} color="default">
            Hủy bỏ
          </Button>
          <Button onClick={this.onSubmit} color="primary">
            Tạo kế hoạch
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  orgs: state.org.data,
  types: state.plan_type.data
});
const mapDispatchToProps = {
  createPlan
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormDialog));