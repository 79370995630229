import { takeEvery, put } from 'redux-saga/effects';
import {
  LOGIN,
  REGISTER,
  RECOVER,
  LOGIN_FACEBOOK,
  LOGIN_GOOGLE
} from '../actions/login';
import axios from 'axios';
import API from '../../configs/API';
import queryString from 'query-string';

function axiosRegister(email, password, name) {
  return axios.post(API.AUTH.SIGNUP, queryString.stringify({
    email,
    password,
    name,
  })).then((response) => {
    if (response.status === 200) {
      const { data } = response;
      return {
        success: true,
        token: data.token,
        user: data,
      };
    }

    return {
      success: false,
    };
  }).catch(error => ({
    success: false,
    message: error.response,
  }));
}

function axiosLogin(email, password) {
  return axios.post(API.AUTH.SIGNIN, queryString.stringify({
    email,
    password,
  })).then((response) => {
    if (response.status === 200) {
      const { data } = response;
      return {
        success: true,
        token: data.token,
        user: data.user,
      };
    }
    return {
      success: false,
    };
  }).catch(error => ({
    success: false,
    message: error.response,
  }));
}

function axiosFacebook(accessToken) {
  return axios.get(API.AUTH.FACEBOOK + '?accessToken=' + encodeURI(accessToken)).then((response) => {
    if (response.status === 200) {
      const { data } = response;
      return {
        success: true,
        token: data.accessToken,
        user: { ...data.user, user_id: data.user.id }
      };
    }
    return {
      success: false,
    };
  }).catch(error => ({
    success: false,
    message: error.response,
  }));
}

function axiosGoogle(accessToken) {
  return axios.get(API.AUTH.GOOGLE + '?accessToken=' + encodeURI(accessToken)).then((response) => {
    if (response.status === 200) {
      const { data } = response;
      return {
        success: true,
        token: data.accessToken,
        user: { ...data.user, user_id: data.user.id }
      };
    }
    return {
      success: false,
    };
  }).catch(error => ({
    success: false,
    message: error.response,
  }));
}


function axiosRecover(email) {
  return axios.post(API.AUTH.RECOVER, {
    email,
  }).then((response) => {
    if (response.status === 200) {
      const { data } = response;
      return {
        success: data.success,
      };
    }

    return {
      success: false,
    };
  }).catch(error => ({
    success: false,
  }));
}

function* loginRegular(obj) {
  const result = yield axiosLogin(obj.email, obj.password);
  if (result.success) {
    yield put({
      type: LOGIN.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: LOGIN.FAIL,
      result,
    });
  }
}

function* loginFacebook(obj) {
  const result = yield axiosFacebook(obj.accessToken);
  if (result.success) {
    yield put({
      type: LOGIN.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: LOGIN.FAIL,
      result,
    });
  }
}

function* loginGoogle(obj) {
  const result = yield axiosGoogle(obj.accessToken);
  if (result.success) {
    yield put({
      type: LOGIN.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: LOGIN.FAIL,
      result,
    });
  }
}


function* recoverPassword(obj) {
  const result = yield axiosRecover(obj.email);
  if (result.success) {
    yield put({
      type: RECOVER.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: RECOVER.FAIL,
      result,
    });
  }
}

function* emailRegister(obj) {
  const result = yield axiosRegister(obj.email, obj.password, obj.name);
  if (result.success) {
    yield put({
      type: REGISTER.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: REGISTER.FAIL,
      result,
    });
  }
}

export function* watchLogin() {
  yield takeEvery(LOGIN.REQUEST, loginRegular);
  yield takeEvery(LOGIN_FACEBOOK, loginFacebook);
  yield takeEvery(LOGIN_GOOGLE, loginGoogle);
  yield takeEvery(RECOVER.REQUEST, recoverPassword);
  yield takeEvery(REGISTER.REQUEST, emailRegister);
}
