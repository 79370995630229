import React from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import TaskRow from '../Task/TaskRow';
import ActivityDialog from './ActivityDialog';
import DeleteActivityDialog from './DeleteActivityDialog';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { deleteActivity } from '../../redux/actions/activity';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

const styles = theme => ({
  table: {
    minWidth: 700,
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  button: {
    marginLeft: 4,
    padding: '4px',
    minWidth: 0
  },
  row: {
    paddingTop: 20,
    paddingBottom: 40
  }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#FAFAFA',
    color: '#000',
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

class ActivityRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      addModal: false,
      idModal: 0,
      deleteModal: false,
      currentActivity: {}
    };
    this.onAddEdit = this.onAddEdit.bind(this);
  }

  renderTask(activity) {
    return <TaskRow activity={activity} key={'act_' + activity.id} />
  }
  componentDidUpdate(prevProps, prevState) {
    if((prevProps.activityUpdated == null && this.props.activityUpdated == true) ||
    (prevProps.activityCreated == null && this.props.activityCreated == true) ||
    (prevProps.activityDeleted == null && this.props.activityDeleted == true)
    ) {
      this.setState({ addModal: false, deleteModal: false });
    }
  }

  onAddEdit(activity) {
    
    this.setState({ 
      currentActivity: activity.id !== undefined ? activity : {},
      idModal: activity.id !== undefined ? activity.id : 0
     }, () => {
      this.setState({ addModal: true });
    })
  }

  render() {
    const { classes, objective } = this.props;
    if(!objective.activities || objective.activities.length == 0) {
      return (
      <TableRow className={classes.row}>
        {this.state.addModal && this.state.idModal == 0 && <ActivityDialog open={this.state.addModal} toggle={()=> this.setState({ addModal: false })} activity={this.state.currentActivity} objective={objective} />}
        <TableCell align="right" colSpan={7}>
          <Button size="small" color="secondary" className={classes.button} onClick={this.onAddEdit}>
            <AddIcon className={classes.rightIcon} />
            Hoạt động
          </Button>
        </TableCell>
      </TableRow>)
    }
    return (
      <TableRow className={classes.row}>
        <TableCell rowSpan={1} colSpan={7} align="right">
          <Button size="small" color="secondary" className={classes.button} onClick={this.onAddEdit}>
            <AddIcon className={classes.rightIcon} />
            Hoạt động
          </Button>
          
          {this.state.addModal && this.state.idModal == 0 && <ActivityDialog open={this.state.addModal} toggle={()=> this.setState({ addModal: false })} activity={this.state.currentActivity} objective={objective} />}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Tên hoạt động</CustomTableCell>
                {/* <CustomTableCell>Mô tả</CustomTableCell> */}
                <CustomTableCell>Nội dung yêu cầu</CustomTableCell>
                <CustomTableCell>Loại hoạt động</CustomTableCell>
                <CustomTableCell>Thời hạn hoàn thành</CustomTableCell>
                <CustomTableCell>Tác vụ</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {objective.activities && objective.activities.map(activity => (
                [<TableRow key={activity.id} hover>
                  {this.state.deleteModal && <DeleteActivityDialog open={this.state.deleteModal} handleClose={() => this.setState({ deleteModal: false })} onDelete={() => this.props.deleteActivity(activity.id)} />}
                  {this.state.addModal && this.state.idModal == activity.id && <ActivityDialog open={this.state.addModal} toggle={()=> this.setState({ addModal: false })} activity={activity} objective={objective} />}
                  <TableCell component="th" scope="row">
                  <Link component={RouterLink} to={'/activity/' + activity.id + '?objective_id=' + objective.id}>
                    <strong>{activity.title}</strong>
                  </Link>
                  </TableCell>
                  {/* <TableCell scope="row">
                    {activity.description}
                  </TableCell> */}
                  <TableCell scope="row">
                    {activity.requirement}
                  </TableCell>
                  <TableCell scope="row">
                    {activity.types && activity.types.map(type =>
                    <p>{type.title}</p>  
                    )}
                  </TableCell>
                  <TableCell scope="row">
                    {activity.deadline}
                  </TableCell>
                  <TableCell align="right">
                    <Button size="small" color="primary" className={classes.button} onClick={(() => this.onAddEdit(activity))}>
                      <EditIcon className={classes.rightIcon}/>
                    </Button>
                    <Button size="small" color="secondary" className={classes.button} onClick={() => this.setState({ deleteModal: true })}>
                      <DeleteIcon className={classes.rightIcon} />
                    </Button>
                  </TableCell>
                </TableRow>,
                this.renderTask(activity)]
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    )
  }
}
const mapStateToProps = state => ({
  activityUpdated: state.activity.update.success,
  activityCreated: state.activity.create.success,
  activityDeleted: state.activity.delete.success,
});
const mapDispatchToProps = {
  deleteActivity
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ActivityRow));