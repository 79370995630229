import MaterialTable from 'material-table'
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ActivityRow from './Activity/ActivityRow';
import { getPlan } from '../redux/actions/plan';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

const styles = theme => ({
  table: {
    minWidth: 700,
    // border: '1px solid #FAFAFA'
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  button: {
    marginLeft: 4,
    padding: '4px',
    minWidth: 0
  }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#FAFAFA',
    color: '#000',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
export class TreeTable extends React.Component {

  renderActivity(objective) {
    return <ActivityRow objective={objective} key={'obj_' + objective.id} />
  }

  componentDidUpdate(prevProps, prevState) {
    if((prevProps.activityUpdated == null && this.props.activityUpdated == true) ||
    (prevProps.activityCreated == null && this.props.activityCreated == true) ||
    (prevProps.activityDeleted == null && this.props.activityDeleted == true) ||
    (prevProps.taskUpdated == null && this.props.taskUpdated == true) ||
    (prevProps.taskCreated == null && this.props.taskCreated == true) ||
    (prevProps.taskDeleted == null && this.props.taskDeleted == true)
    ) {
      this.props.getPlan(this.props.currentPlan.id);
    }
  }

  render() {
    const { classes, objectives } = this.props;

    return (
      <div>
        <Button size="small" color="secondary" className={classes.button} onClick={this.props.onAdd}>
          <AddIcon className={classes.rightIcon} />
          Mục tiêu
        </Button>
      
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell>Tên mục tiêu</CustomTableCell>
            <CustomTableCell>Chịu trách nhiệm</CustomTableCell>
            <CustomTableCell>Thời gian</CustomTableCell>
            <CustomTableCell>Loại mục tiêu </CustomTableCell>
            <CustomTableCell>Nhóm mục tiêu </CustomTableCell>
            <CustomTableCell>Tác vụ</CustomTableCell>
          </TableRow>
          
        </TableHead>
        <TableBody>
          {objectives.map((objective, index) => (
            [<TableRow key={objective.id} hover>
              <TableCell component="th" scope="row">
                <Link component={RouterLink} to={'/objective/' + objective.id + '?plan_id=' + objective.plan_id}>
                  <strong>{objective.title}</strong>
                </Link>
              </TableCell>
              <TableCell scope="row">
                <p style={{ 'white-space': 'pre-line'}} dangerouslySetInnerHTML={{__html: objective.support}}></p>
              </TableCell>
              <TableCell scope="row">
                <p>Bắt đầu: <strong>{objective.start_at}</strong></p>
                <p>Kết thúc: <strong>{objective.end_at}</strong></p>
                <p>Số ngày: <strong>{objective.days}</strong></p>
              </TableCell>
              <TableCell scope="row">
                {objective.types && objective.types.map(type => 
                  <p>{type.title}</p>  
                )}
              </TableCell>
              <TableCell scope="row">
                {objective.groups && objective.groups.map(group => 
                  <p>{group.title}</p>  
                )}
              </TableCell>
              <TableCell align="right">
                <Button size="small" color="primary" className={classes.button} onClick={() => this.props.onEdit(objective)}>
                  <EditIcon className={classes.rightIcon}/>
                </Button>
                <Button size="small" color="secondary" className={classes.button} onClick={() => this.props.onDelete(objective)}>
                  <DeleteIcon className={classes.rightIcon} />
                </Button>
              </TableCell>
            </TableRow>,
            this.renderActivity(objective)]
          ))}
        </TableBody>
      </Table>
      </div>
    );
  }
}

TreeTable.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  activityUpdated: state.activity.update.success,
  activityCreated: state.activity.create.success,
  activityDeleted: state.activity.delete.success,
  taskUpdated: state.task.update.success,
  taskCreated: state.task.create.success,
  taskDeleted: state.task.delete.success,
  currentPlan: state.plan.currentPlan,
});
const mapDispatchToProps = {
  getPlan
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TreeTable));