import { createRequestTypes } from './utils';

export const GET_OBJECTIVES = createRequestTypes('GET_OBJECTIVES');
export const GET_OBJECTIVE = createRequestTypes('GET_OBJECTIVE');
export const CREATE_OBJECTIVE = createRequestTypes('CREATE_OBJECTIVE');
export const UPDATE_OBJECTIVE = createRequestTypes('UPDATE_OBJECTIVE');
export const DELETE_OBJECTIVE = createRequestTypes('DELETE_OBJECTIVE');

export const getObjectives = planId => ({ type: GET_OBJECTIVES.REQUEST, planId });
export const getObjective = id => ({ type: GET_OBJECTIVE.REQUEST, id });
export const createObjective = (data) => ({ type: CREATE_OBJECTIVE.REQUEST, data })
export const updateObjective = (id, data) => ({ type: UPDATE_OBJECTIVE.REQUEST, id, data })
export const deleteObjective = id => ({ type: DELETE_OBJECTIVE.REQUEST, id });