export const ROOT = 'https://slearning.com.vn/';
// export const ROOT = 'http://localhost:3001/';
export const PLAN_ROOT = ROOT + 'plan/';
export const API_ROOT = ROOT + 'api/';

export default {
  AUTH: {
    SIGNIN: API_ROOT + 'auth/signin',
    SIGNUP: API_ROOT + 'auth/signup',
    RECOVER: API_ROOT + 'auth/forgot_password',
    GOOGLE: API_ROOT + 'auth/google',
    FACEBOOK: API_ROOT + 'auth/facebook'
  },
  PLAN: {
    LIST: PLAN_ROOT + 'plans',
    CREATE: PLAN_ROOT + 'plans',
    SHOW: PLAN_ROOT + 'plans/{id}',
    UPDATE: PLAN_ROOT + 'plans/{id}',
    DELETE: PLAN_ROOT + 'plans/{id}',
  },
  PLAN_TYPE: {
    LIST: PLAN_ROOT + 'plan_types',
    CREATE: PLAN_ROOT + 'plan_types',
    SHOW: PLAN_ROOT + 'plan_types/{id}',
    UPDATE: PLAN_ROOT + 'plan_types/{id}',
    DELETE: PLAN_ROOT + 'plan_types/{id}',
  },
  OBJECTIVE_TYPE: {
    LIST: PLAN_ROOT + 'objective_types',
    CREATE: PLAN_ROOT + 'objective_types',
    SHOW: PLAN_ROOT + 'objective_types/{id}',
    UPDATE: PLAN_ROOT + 'objective_types/{id}',
    DELETE: PLAN_ROOT + 'objective_types/{id}',
  },
  OBJECTIVE_GROUP: {
    LIST: PLAN_ROOT + 'objective_groups',
    CREATE: PLAN_ROOT + 'objective_groups',
    SHOW: PLAN_ROOT + 'objective_groups/{id}',
    UPDATE: PLAN_ROOT + 'objective_groups/{id}',
    DELETE: PLAN_ROOT + 'objective_groups/{id}',
  },
  OBJECTIVE: {
    LIST: PLAN_ROOT + 'objectives?plan_id=:plan_id',
    CREATE: PLAN_ROOT + 'objectives',
    SHOW: PLAN_ROOT + 'objectives/{id}',
    UPDATE: PLAN_ROOT + 'objectives/{id}',
    DELETE: PLAN_ROOT + 'objectives/{id}',
  },
  ORG: {
    LIST: PLAN_ROOT + 'plans/orgs'
  },
  TASK: {
    LIST: PLAN_ROOT + 'tasks?activity_id=:activity_id',
    CREATE: PLAN_ROOT + 'tasks',
    UPDATE: PLAN_ROOT + 'tasks/{id}',
    DELETE: PLAN_ROOT + 'tasks/{id}',
  },
  TASK_TYPE: {
    LIST: PLAN_ROOT + 'task_types',
    CREATE: PLAN_ROOT + 'task_types',
    SHOW: PLAN_ROOT + 'task_types/{id}',
    UPDATE: PLAN_ROOT + 'task_types/{id}',
    DELETE: PLAN_ROOT + 'task_types/{id}',
  },
  TASK_NATURE: {
    LIST: PLAN_ROOT + 'task_natures',
    CREATE: PLAN_ROOT + 'task_natures',
    SHOW: PLAN_ROOT + 'task_natures/{id}',
    UPDATE: PLAN_ROOT + 'task_natures/{id}',
    DELETE: PLAN_ROOT + 'task_natures/{id}',
  },
  ACTIVITY: {
    LIST: PLAN_ROOT + 'activities?objective_id=:obj_id',
    SHOW: PLAN_ROOT + 'activities/{id}',
    CREATE: PLAN_ROOT + 'activities',
    UPDATE: PLAN_ROOT + 'activities/{id}',
    DELETE: PLAN_ROOT + 'activities/{id}',
  },
  ACTIVITY_TYPE: {
    LIST: PLAN_ROOT + 'activity_types',
    CREATE: PLAN_ROOT + 'activity_types',
    SHOW: PLAN_ROOT + 'activity_types/{id}',
    UPDATE: PLAN_ROOT + 'activity_types/{id}',
    DELETE: PLAN_ROOT + 'activity_types/{id}',
  },
};
