import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_PLAN_TYPES, CREATE_PLAN_TYPE, GET_PLAN_TYPE, DELETE_PLAN_TYPE, UPDATE_PLAN_TYPE
} from '../actions/plan_type';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosDelete, axiosPut } from './utils';
// import { sendMessage } from '../actions/conversations';

function* getPlans(obj) {
  const result = yield axiosGet(API.PLAN_TYPE.LIST);

  if (result.success) {
    yield put({
      type: GET_PLAN_TYPES.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_PLAN_TYPES.FAIL,
      result
    });
  }
}

function* createPlan(obj) {
  const result = yield axiosPost(API.PLAN_TYPE.CREATE, obj.data);

  if (result.success) {
    yield put({
      type: CREATE_PLAN_TYPE.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_PLAN_TYPES.REQUEST,
      page: 1
    });
  } else if(result.success == false && result.errorMessage.response.status == 400) {
    yield put({
      type: CREATE_PLAN_TYPE.FAIL,
      result: result.errorMessage.response.data.errors
    });
  }
  else {
    
    yield put({
      type: CREATE_PLAN_TYPE.FAIL,
      result: ["Unknow error"]
    });
  }
}

function* updatePlan(obj) {
  const result = yield axiosPut(API.PLAN_TYPE.UPDATE.replace('{id}', obj.id), obj.data);

  if (result.success) {
    yield put({
      type: UPDATE_PLAN_TYPE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_PLAN_TYPE.FAIL,
      result
    });
  }
}
function* getPlan(obj) {
  const result = yield axiosGet(API.PLAN_TYPE.SHOW.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: GET_PLAN_TYPE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_PLAN_TYPE.FAIL,
      result
    });
  }
}

function* deletePlan(obj) {
  const result = yield axiosDelete(API.PLAN_TYPE.DELETE.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: DELETE_PLAN_TYPE.SUCCESS
    });
  } else {
    yield put({
      type: DELETE_PLAN_TYPE.FAIL
    });
  }
}



export function* watchPlanTypes() {
  yield takeEvery(GET_PLAN_TYPES.REQUEST, getPlans);
  yield takeEvery(CREATE_PLAN_TYPE.REQUEST, createPlan);
  yield takeEvery(GET_PLAN_TYPE.REQUEST, getPlan);
  yield takeEvery(DELETE_PLAN_TYPE.REQUEST, deletePlan);
  yield takeEvery(UPDATE_PLAN_TYPE.REQUEST, updatePlan);
}

