import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import sagas from './redux/sagas';
import reducers from './redux/reducers';
import axios from 'axios';
import Dashboard from './containers/Dashboard';
import DrawerApp from './containers/Drawer';
import SignIn from './containers/Signin';
import Plan from './containers/Plan';
import Objective from './containers/Objective';
import Activity from './containers/Activity';
import Orgs from './containers/Orgs';
import { withTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

let rehydrationComplete;
const rehydrationPromise = new Promise((resolve, reject) => {
  rehydrationComplete = resolve;
});
const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return reducers(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()
const store = compose(applyMiddleware(sagaMiddleware))(
  createStore
)(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);


sagaMiddleware.run(sagas)

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rehydrate: false
    }
    this.persistor = persistStore(store, {}, () => {
      rehydrationComplete();
      const { token } = store.getState().login;
      axios.defaults.headers.common.tenant = 'khltt'
      axios.defaults.headers.common.Authorization = 'Bearer ' + token;
      this.setState({ rehydrated: true });
    });
  }

  render() {
    if(!this.state.rehydrated){
      return <div>Loading</div>;
    }
    return (
      <PersistGate loading={null} persistor={this.persistor}>
        <Router>
          <Provider store={store}>
            <Switch>
              <Route exact path="/login" component={SignIn} />
              <DrawerApp>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/orgs" component={Orgs} />
                <Route exact path="/activity/:id" component={Activity} />
                <Route exact path="/objective/:id" component={Objective} />
                <Route exact path="/plan/:id" component={Plan} />
                <Route exact path="/plans" component={Plan} />
              </DrawerApp>
            </Switch>
          </Provider>
        </Router>
      </PersistGate>
    );
  }
}

export default App;
