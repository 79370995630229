import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DeleteObjectiveDialog from './DeleteObjectiveDialog';
import DeleteActivityDialog from '../Activity/DeleteActivityDialog';
import ActivityTable from './ActivityTable';
import Button from '@material-ui/core/Button';
import ActivityDialog from '../Activity/ActivityDialog';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import queryString from 'query-string'
import { deletePlan, updatePlan } from '../../redux/actions/plan';
import { deleteObjective, getObjective, updateObjective } from '../../redux/actions/objective';
import { deleteActivity } from '../../redux/actions/activity'
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  content: {
    paddingTop: theme.spacing.unit * 2
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  formControl: {
    marginBottom: theme.spacing.unit * 3,

  }
});

class ObjectiveDetail extends React.Component {
  state = {
    value: 0,
    addModal: false,
    deleteModal: false,
    deleteActivityModal: false,
    currentObjective: {},
    currentActivity: {}
  };

  constructor(props) {
    super(props);

    this.renderTabActivity = this.renderTabActivity.bind(this);
    this.renderTabInfo = this.renderTabInfo.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.refresh = this.refresh.bind(this);
    this.deleteObjective = this.deleteObjective.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  refresh() {
    if(this.props.currentObjective) {
      this.props.getObjective(this.props.currentObjective.id);
      this.setState({
        addModal: false,
        deleteActivityModal: false
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if((prevProps.objectiveCreated == null && this.props.objectiveCreated == true) ||
    (prevProps.objectiveUpdated == null && this.props.objectiveUpdated == true) ||
    (prevProps.activityDeleted == null && this.props.activityDeleted == true) ||
    (prevProps.activityUpdated == null && this.props.activityUpdated == true) ||
    (prevProps.activityCreated == null && this.props.activityCreated == true) 
      ) {
      this.refresh();
   
    }
    if(prevProps.objectiveDeleted == null && this.props.objectiveDeleted == true) {
      this.setState({
        deleteActivityModal: false
      })
    }
    if(prevProps.currentObjective && prevProps.currentObjective.id !== this.props.currentObjective.id) {
      this.setState({ currentObjective: this.props.currentObjective });
    }
   
  }

  onEdit(activity) {
    this.setState({
      addModal: true,
      currentActivity: activity
    })
  }

  onUpdate() {
    const { currentObjective } = this.state;
    this.props.updateObjective(currentObjective.id, this.state.currentObjective);
  }

  onDelete(activity) {
    this.setState({
      deleteActivityModal: true,
      currentActivity: activity
    })
  }

  onAdd() {
    this.setState({
      addModal: true,
      currentActivity: {}
    })
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  toggleAddModal = () => {
    this.setState({addModal: !this.state.addModal})
  }

  deleteObjective(id) {
    this.setState({deleteActivityModal: false})
    this.props.deleteObjective(id);
  }

  renderTabActivity() {
    const { classes, currentPlan, currentObjective } = this.props;
    if(!currentObjective)
      return null;
    return (
      <div className={classes.content}>
        <Button size="small" color="secondary" className={classes.button} onClick={this.onAdd}>
          <AddIcon className={classes.rightIcon} />
          Thêm hoạt động
        </Button>
        <DeleteActivityDialog open={this.state.deleteActivityModal} handleClose={() => this.setState({ deleteActivityModal: false })} onDelete={(id) => this.props.deleteActivity(this.state.currentActivity.id)} />
        <ActivityTable activities={currentObjective.activities} onEdit={this.onEdit} onDelete={this.onDelete} />
        {this.state.addModal && <ActivityDialog open={this.state.addModal} toggle={this.toggleAddModal} activity={this.state.currentActivity} objective={currentObjective} />}
      </div> 
    )
  }

  renderTabInfo() {
    const { classes, deleteObjective } = this.props;
    const { currentObjective } = this.state;
    if(!currentObjective)
      return null;
    return (
      <div className={classes.content}>
        <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Tên mục tiêu"
            type="text"
            fullWidth
            value={currentObjective.title || ''}
            onChange={ref => this.setState({ currentObjective: { ...this.state.currentObjective, title: ref.target.value } })}
          />
          <TextField
            margin="dense"
            id="description"
            label="Nội dung"
            multiline
            rows={5}
            type="text"
            fullWidth
            value={currentObjective.description || ''}
            onChange={ref => this.setState({ currentObjective: { ...this.state.currentObjective, description: ref.target.value } })}
          />
          
        
        <Button variant="contained" color="primary" className={classes.button} onClick={this.onUpdate}>Lưu mục tiêu</Button>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => this.setState({ deleteModal: true }) }>Xóa mục tiêu</Button>
        <DeleteObjectiveDialog open={this.state.deleteModal} handleClose={() => this.setState({ deleteModal: false })} onDelete={() => deleteObjective(currentObjective.id)} />
      </div>
    )
  }

  render() {
    const { classes, currentObjective } = this.props;
    const { value } = this.state;
    
    return (
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Danh sách hoạt động"
          />
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Thông tin hoạt động"
          />
          
        </Tabs>
        { value === 0 && this.renderTabActivity() }
        { value === 1 && this.renderTabInfo() }
      </div>
    );
  }
}

ObjectiveDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  currentObjective: state.objective.currentObjective,
  objectives: state.plan.objectives,
  objectiveCreated: state.objective.create.success,
  objectiveUpdated: state.objective.update.success,
  objectiveDeleted: state.objective.delete.success,
  activityCreated: state.activity.create.success,
  activityUpdated: state.activity.update.success,
  activityDeleted: state.activity.delete.success,

  orgs: state.org.data,
});
const mapDispatchToProps = {
  deleteObjective,
  updateObjective,
  getObjective,
  deleteActivity
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ObjectiveDetail));