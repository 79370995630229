import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  root: {
    width: '100%'
  },
  item: {
    paddingLeft: 0,
    paddingRight: 0
  }
});

function PlanList(props) {
  const { classes, plans, select } = props;
  return (
    <List className={classes.root}>
      {
        plans.map(plan => {
          return <ListItem component={Link} to={"/plan/" + plan.id} divider className={classes.item} key={plan.id}>
            <ListItemText primary={plan.title} secondary={plan.description} />
          </ListItem>
        })
      }
    </List>
  );
}

PlanList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  plans: state.plan.plans,
  currentPlan: state.plan.currentPlan,
});
const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlanList));