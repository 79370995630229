import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_OBJECTIVE_GROUPS, CREATE_OBJECTIVE_GROUP, GET_OBJECTIVE_GROUP, DELETE_OBJECTIVE_GROUP, UPDATE_OBJECTIVE_GROUP
} from '../actions/objective_group';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosDelete, axiosPut } from './utils';
// import { sendMessage } from '../actions/conversations';

function* getObjectiveGroups(obj) {
  const result = yield axiosGet(API.OBJECTIVE_GROUP.LIST);

  if (result.success) {
    yield put({
      type: GET_OBJECTIVE_GROUPS.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_OBJECTIVE_GROUPS.FAIL,
      result
    });
  }
}

function* createObjectiveGroup(obj) {
  const result = yield axiosPost(API.OBJECTIVE_GROUP.CREATE, obj.data);

  if (result.success) {
    yield put({
      type: CREATE_OBJECTIVE_GROUP.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_OBJECTIVE_GROUPS.REQUEST,
      page: 1
    });
  } else if(result.success == false && result.errorMessage.response.status == 400) {
    yield put({
      type: CREATE_OBJECTIVE_GROUP.FAIL,
      result: result.errorMessage.response.data.errors
    });
  }
  else {
    
    yield put({
      type: CREATE_OBJECTIVE_GROUP.FAIL,
      result: ["Unknow error"]
    });
  }
}

function* updateObjectiveGroup(obj) {
  const result = yield axiosPut(API.OBJECTIVE_GROUP.UPDATE.replace('{id}', obj.id), obj.data);

  if (result.success) {
    yield put({
      type: UPDATE_OBJECTIVE_GROUP.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_OBJECTIVE_GROUP.FAIL,
      result
    });
  }
}
function* getObjectiveGroup(obj) {
  const result = yield axiosGet(API.OBJECTIVE_GROUP.SHOW.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: GET_OBJECTIVE_GROUP.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_OBJECTIVE_GROUP.FAIL,
      result
    });
  }
}

function* deleteObjectiveGroup(obj) {
  const result = yield axiosDelete(API.OBJECTIVE_GROUP.DELETE.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: DELETE_OBJECTIVE_GROUP.SUCCESS
    });
  } else {
    yield put({
      type: DELETE_OBJECTIVE_GROUP.FAIL
    });
  }
}



export function* watchObjectiveGroups() {
  yield takeEvery(GET_OBJECTIVE_GROUPS.REQUEST, getObjectiveGroups);
  yield takeEvery(CREATE_OBJECTIVE_GROUP.REQUEST, createObjectiveGroup);
  yield takeEvery(GET_OBJECTIVE_GROUP.REQUEST, getObjectiveGroup);
  yield takeEvery(DELETE_OBJECTIVE_GROUP.REQUEST, deleteObjectiveGroup);
  yield takeEvery(UPDATE_OBJECTIVE_GROUP.REQUEST, updateObjectiveGroup);
}

