import { createRequestTypes } from './utils';

export const GET_ACTIVITIES = createRequestTypes('GET_ACTIVITIES');
export const GET_ACTIVITY = createRequestTypes('GET_ACTIVITY');
export const CREATE_ACTIVITY = createRequestTypes('CREATE_ACTIVITY');
export const UPDATE_ACTIVITY = createRequestTypes('UPDATE_ACTIVITY');
export const DELETE_ACTIVITY = createRequestTypes('DELETE_ACTIVITY');

export const getActivities = objectiveId => ({ type: GET_ACTIVITIES.REQUEST, objectiveId });
export const getActivity = id => ({ type: GET_ACTIVITY.REQUEST, id });
export const createActivity = data => ({ type: CREATE_ACTIVITY.REQUEST, data })
export const updateActivity = (id, data) => ({ type: UPDATE_ACTIVITY.REQUEST, id, data })
export const deleteActivity = id => ({ type: DELETE_ACTIVITY.REQUEST, id });