import { createRequestTypes } from './utils';

export const GET_TASK_TYPES = createRequestTypes('GET_TASK_TYPES');
export const CREATE_TASK_TYPE = createRequestTypes('CREATE_TASK_TYPE');
export const UPDATE_TASK_TYPE = createRequestTypes('UPDATE_TASK_TYPE');
export const GET_TASK_TYPE = createRequestTypes('GET_TASK_TYPE');
export const DELETE_TASK_TYPE = createRequestTypes('DELETE_TASK_TYPE');

export const CLEAN_TASK_TYPE = 'CLEAN_TASK_TYPE';
export const cleanTaskType = () => ({ type: CLEAN_TASK_TYPE });

export const getTaskTypes = page => ({
  type: GET_TASK_TYPES.REQUEST,
  page
});

export const createTaskType = data => ({
  type: CREATE_TASK_TYPE.REQUEST,
  data
});

export const updateTaskType = (id, data) => ({
  type: UPDATE_TASK_TYPE.REQUEST,
  id,
  data
});

export const getTaskType = id => ({
  type: GET_TASK_TYPE.REQUEST,
  id
});

export const deleteTaskType = id => ({
  type: DELETE_TASK_TYPE.REQUEST,
  id
});
