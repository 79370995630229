import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_TASK_NATURES, CREATE_TASK_NATURE, GET_TASK_NATURE, DELETE_TASK_NATURE, UPDATE_TASK_NATURE
} from '../actions/task_nature';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosDelete, axiosPut } from './utils';
// import { sendMessage } from '../actions/conversations';

function* getTaskNatures(obj) {
  const result = yield axiosGet(API.TASK_NATURE.LIST);

  if (result.success) {
    yield put({
      type: GET_TASK_NATURES.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_TASK_NATURES.FAIL,
      result
    });
  }
}

function* createTaskNature(obj) {
  const result = yield axiosPost(API.TASK_NATURE.CREATE, obj.data);

  if (result.success) {
    yield put({
      type: CREATE_TASK_NATURE.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_TASK_NATURES.REQUEST,
      page: 1
    });
  } else if(result.success == false && result.errorMessage.response.status == 400) {
    yield put({
      type: CREATE_TASK_NATURE.FAIL,
      result: result.errorMessage.response.data.errors
    });
  }
  else {
    
    yield put({
      type: CREATE_TASK_NATURE.FAIL,
      result: ["Unknow error"]
    });
  }
}

function* updateTaskNature(obj) {
  const result = yield axiosPut(API.TASK_NATURE.UPDATE.replace('{id}', obj.id), obj.data);

  if (result.success) {
    yield put({
      type: UPDATE_TASK_NATURE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_TASK_NATURE.FAIL,
      result
    });
  }
}
function* getTaskNature(obj) {
  const result = yield axiosGet(API.TASK_NATURE.SHOW.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: GET_TASK_NATURE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_TASK_NATURE.FAIL,
      result
    });
  }
}

function* deleteTaskNature(obj) {
  const result = yield axiosDelete(API.TASK_NATURE.DELETE.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: DELETE_TASK_NATURE.SUCCESS
    });
  } else {
    yield put({
      type: DELETE_TASK_NATURE.FAIL
    });
  }
}



export function* watchTaskNatures() {
  yield takeEvery(GET_TASK_NATURES.REQUEST, getTaskNatures);
  yield takeEvery(CREATE_TASK_NATURE.REQUEST, createTaskNature);
  yield takeEvery(GET_TASK_NATURE.REQUEST, getTaskNature);
  yield takeEvery(DELETE_TASK_NATURE.REQUEST, deleteTaskNature);
  yield takeEvery(UPDATE_TASK_NATURE.REQUEST, updateTaskNature);
}

