import {
  GET_OBJECTIVE_GROUPS,
  GET_OBJECTIVE_GROUP,
  CREATE_OBJECTIVE_GROUP,
  UPDATE_OBJECTIVE_GROUP,
  DELETE_OBJECTIVE_GROUP
} from '../actions/objective_group';

const initialState = {
  isFetching: false,
  data: [],
  errorMessage: null,
  currentObjective: {},
  create: {
    isRequesting: false,
    success: null,
    msg: null,
  },
  update: {
    isRequesting: false,
    success: null,
    msg: null,
  },
  delete: {
    isRequesting: false,
    success: null,
    msg: null,
  }
};

export default (state = initialState, action) => {

  switch (action.type) {
    case GET_OBJECTIVE_GROUPS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GET_OBJECTIVE_GROUPS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.result.objective_groups
      };

    case GET_OBJECTIVE_GROUPS.FAIL:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.result,
      };
    
    case GET_OBJECTIVE_GROUP.REQUEST:
      return {
        ...state,
        currentObjective: {},
        isFetching: true,
      };

    case GET_OBJECTIVE_GROUP.SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentObjective: action.result
      };

    case GET_OBJECTIVE_GROUP.FAIL:
      return {
        ...state,
        isFetching: false,
        currentObjective: {},
        errorMessage: action.result,
      };

    case CREATE_OBJECTIVE_GROUP.REQUEST:
      return {
        ...state,
        create: {
          isRequesting: true,
          success: null,
          msg: null,
        }
      };

    case CREATE_OBJECTIVE_GROUP.SUCCESS:
      return {
        ...state,
        create: {
          isRequesting: false,
          success: true,
          msg: action.result,
        }
      };

    case CREATE_OBJECTIVE_GROUP.FAIL:
      return {
        ...state,
        create: {
          isRequesting: false,
          success: false,
          msg: action.result,
        }
      };
      
    case UPDATE_OBJECTIVE_GROUP.REQUEST:
      return {
        ...state,
        update: {
          isRequesting: true,
          success: null,
          msg: null,
        }
      };

    case UPDATE_OBJECTIVE_GROUP.SUCCESS:
      return {
        ...state,
        update: {
          isRequesting: false,
          success: true,
          msg: action.result,
        }
      };

    case UPDATE_OBJECTIVE_GROUP.FAIL:
      return {
        ...state,
        update: {
          isRequesting: false,
          success: false,
          msg: action.result,
        }
      };
      
    case DELETE_OBJECTIVE_GROUP.REQUEST:
      return {
        ...state,
        delete: {
          isRequesting: true,
          success: null,
          msg: null,
        }
      };

    case DELETE_OBJECTIVE_GROUP.SUCCESS:
      return {
        ...state,
        delete: {
          isRequesting: false,
          success: true,
          msg: action.result,
        }
      };

    case DELETE_OBJECTIVE_GROUP.FAIL:
      return {
        ...state,
        delete: {
          isRequesting: false,
          success: false,
          msg: action.result,
        }
      };

    default:
      return state;
  }
};
