import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { createObjective, updateObjective } from '../../redux/actions/objective';

const styles = theme => ({
  paper: {

  },
  textField: {
    marginBottom: theme.spacing.unit * 3
  }
})

class FormDialog extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      title: '',
      description: '',
      types: [],
      groups: [],
      end_at: null,
      start_at: null,
      //days: 1
    };
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeGroup = this.onChangeGroup.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    
    if(props.objective.id !== state.id) {
      console.log(props.objective)
      return {
        id: props.objective.id,
        title: props.objective.title,
        description: props.objective.description,
        support: props.objective.support,
        end_at: props.objective.end_at,
        start_at: props.objective.start_at,
        //days: props.objective.days,
        groups: props.objective.groups ? props.objective.groups.map(x => x.id) : [],
        types: props.objective.types ? props.objective.types.map(x => x.id) : [],
      }
    }
    return null;
  }

  onSubmit = () => {
    const data = {
      title: this.state.title,
      description: this.state.description,
      support: this.state.support,
      end_at: this.state.end_at,
      start_at: this.state.start_at,
      //days: this.state.days,
      types: this.state.types,
      groups: this.state.groups,
      plan_id: this.props.plan.id
    };
    if(this.state.id) {
      this.props.updateObjective(this.state.id, data)
    }
    else {
      this.props.createObjective(data)
    }
  }

  onChangeType(ref) {
    if(ref.target.checked) {
      this.setState({
        types: [...this.state.types, ref.target.value]
      })
    }
    else {
      let types = this.state.types.filter(x => x !== ref.target.value);

      this.setState({
        types
      })
    }
  }

  onChangeGroup(ref) {
    if(ref.target.checked) {
      this.setState({
        groups: [...this.state.groups, ref.target.value]
      })
    }
    else {
      let groups = this.state.groups.filter(x => x !== ref.target.value);

      this.setState({
        groups
      })
    }
  }

  render() {
    const { open, toggle, plan, classes, types, groups } = this.props
    
    return (
      <Dialog
        open={open}
        onClose={toggle}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{this.state.id ? 'Sửa mục tiêu' : 'Thêm mục tiêu'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Tên mục tiêu"
            type="text"
            fullWidth
            value={this.state.title}
            className={classes.textField}
            onChange={ref => this.setState({ title: ref.target.value })}
          />
          <TextField
            className={classes.textField}
            margin="dense"
            id="support"
            label="Chịu trách nhiệm"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={this.state.support}
            onChange={ref => this.setState({ support: ref.target.value })}
          />
          <TextField
            id="date"
            label="Thời gian bắt đầu"
            type="date"
            fullWidth
            value={this.state.start_at}
            className={classes.textField}
            onChange={ref => this.setState({ start_at: ref.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* <TextField
            margin="dense"
            id="days"
            label="Số ngày thực hiện"
            multiline
            type="text"
            fullWidth
            value={this.state.days}
            className={classes.textField}
            onChange={ref => this.setState({ days: ref.target.value })}
          /> */}
          <TextField
            id="date"
            label="Thời gian kết thúc"
            type="date"
            fullWidth
            value={this.state.end_at}
            className={classes.textField}
            onChange={ref => this.setState({ end_at: ref.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl className={classes.formControl}>
            <FormLabel  htmlFor="age-native-helper">Loại mục tiêu</FormLabel>
            <FormGroup>
              {types && types.map(type => 
                <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.types.find(x => x == type.id) !== undefined}
                    onChange={this.onChangeType}
                    value={type.id}
                  />
                }
                label={type.title}
              />
              )}
              
            </FormGroup>            
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <FormLabel  htmlFor="age-native-helper">Nhóm mục tiêu</FormLabel>
            <FormGroup>
              {groups && groups.map(group => 
                <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.groups.find(x => x == group.id) !== undefined}
                    onChange={this.onChangeGroup}
                    value={group.id}
                  />
                }
                label={group.title}
              />
              )}
              
            </FormGroup>            
          </FormControl>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle} color="default">
            Hủy bỏ
          </Button>
          <Button onClick={this.onSubmit} color="primary">
            {this.state.id ? 'Cập nhật' : 'Tạo mục tiêu'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  types: state.objective_type.data,
  groups: state.objective_group.data
});
const mapDispatchToProps = {
  createObjective,
  updateObjective
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormDialog));