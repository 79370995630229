import React from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import TaskDialog from './TaskDialog';
import DeleteTaskDialog from './DeleteTaskDialog';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { deleteTask } from '../../redux/actions/task';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  table: {
    minWidth: 700,
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  button: {
    marginLeft: 4,
    padding: '4px',
    minWidth: 0
  }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#FAFAFA',
    color: '#000',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class TaskRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      addModal: false,
      idModal: 0,
      deleteModal: false,
      currentTask: {}
    };
    this.onAddEdit = this.onAddEdit.bind(this);
  }

  
  componentDidUpdate(prevProps, prevState) {
    if((prevProps.taskUpdated == null && this.props.taskUpdated == true) ||
    (prevProps.taskCreated == null && this.props.taskCreated == true) ||
    (prevProps.taskDeleted == null && this.props.taskDeleted == true)
    ) {
      this.setState({ addModal: false, deleteModal: false });
    }
  }

  onAddEdit(task) {
    
    this.setState({ 
      currentTask: task.id !== undefined ? task : {},
      idModal: task.id !== undefined ? task.id : 0
     }, () => {
      this.setState({ addModal: true });
    })
  }

  render() {
    const { classes, activity } = this.props;
    if(!activity.tasks || activity.tasks.length == 0) {
      return (
      <TableRow>
        {this.state.addModal && this.state.idModal == 0 && <TaskDialog open={this.state.addModal} toggle={()=> this.setState({ addModal: false })} task={this.state.currentTask} activity={activity}  />}
        <TableCell align="right" colSpan={6}>
          <Button size="small" color="secondary" className={classes.button} onClick={this.onAddEdit}>
            <AddIcon className={classes.rightIcon} />
            Công việc
          </Button>
        </TableCell>
      </TableRow>)
    }
    return (
      <TableRow>
        <TableCell rowSpan={1} colSpan={6} align="right">
          <Button size="small" color="secondary" className={classes.button} onClick={this.onAddEdit}>
            <AddIcon className={classes.rightIcon} />
            Công việc
          </Button>
          
          {this.state.addModal && this.state.idModal == 0 && <TaskDialog open={this.state.addModal} toggle={()=> this.setState({ addModal: false })}  task={this.state.currentTask} activity={activity}/>}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Tên công việc</CustomTableCell>
                {/* <CustomTableCell>Nội dung công việc</CustomTableCell> */}
                <CustomTableCell>Thời hạn hoàn thành</CustomTableCell>
                <CustomTableCell>Người liên quan</CustomTableCell>
                <CustomTableCell>Trạng thái</CustomTableCell>
                {/* <CustomTableCell>Kết quả</CustomTableCell> */}
                <CustomTableCell>Tác vụ</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activity.tasks && activity.tasks.map(task => (
                <TableRow key={task.id} hover>
                  {this.state.deleteModal && <DeleteTaskDialog open={this.state.deleteModal} handleClose={() => this.setState({ deleteModal: false })} onDelete={() => this.props.deleteTask(task.id)} />}
                  {this.state.addModal && this.state.idModal == task.id && <TaskDialog open={this.state.addModal} toggle={()=> this.setState({ addModal: false })} task={this.state.currentTask} activity={activity} />}

                  <TableCell component="th" scope="row">
                    <strong>{task.title}</strong>
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                  {task.description}
                  </TableCell> */}
                  <TableCell scope="row">
                    {task.deadline}
                  </TableCell>
                  <TableCell scope="row">
                    <p style={{ 'white-space': 'pre-line'}} dangerouslySetInnerHTML={{__html: task.support}}></p>
                  </TableCell>
                  <TableCell scope="row">
                  <Chip
                    label={task.status.label}
                    color={task.status.code == 'pending' ? 'default' : (task.status.code == 'doing' ? 'secondary' : 'primary')}
                  />
                  </TableCell>
                  {/* <TableCell scope="row">
                    {task.result}
                  </TableCell> */}
                  <TableCell align="right">
                    <Button size="small" color="primary" className={classes.button} onClick={(() => this.onAddEdit(task))}>
                      <EditIcon className={classes.rightIcon}/>
                    </Button>
                    <Button size="small" color="secondary" className={classes.button} onClick={() => this.setState({ deleteModal: true })}>
                      <DeleteIcon className={classes.rightIcon} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    )
  }
}
const mapStateToProps = state => ({
  taskUpdated: state.task.update.success,
  taskCreated: state.task.create.success,
  taskDeleted: state.task.delete.success,
});
const mapDispatchToProps = {
  deleteTask
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TaskRow));