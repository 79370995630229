import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_OBJECTIVE_TYPES, CREATE_OBJECTIVE_TYPE, GET_OBJECTIVE_TYPE, DELETE_OBJECTIVE_TYPE, UPDATE_OBJECTIVE_TYPE
} from '../actions/objective_type';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosDelete, axiosPut } from './utils';
// import { sendMessage } from '../actions/conversations';

function* getGroupTypes(obj) {
  const result = yield axiosGet(API.OBJECTIVE_TYPE.LIST);

  if (result.success) {
    yield put({
      type: GET_OBJECTIVE_TYPES.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_OBJECTIVE_TYPES.FAIL,
      result
    });
  }
}

function* createGroupType(obj) {
  const result = yield axiosPost(API.OBJECTIVE_TYPE.CREATE, obj.data);

  if (result.success) {
    yield put({
      type: CREATE_OBJECTIVE_TYPE.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_OBJECTIVE_TYPES.REQUEST,
      page: 1
    });
  } else if(result.success == false && result.errorMessage.response.status == 400) {
    yield put({
      type: CREATE_OBJECTIVE_TYPE.FAIL,
      result: result.errorMessage.response.data.errors
    });
  }
  else {
    
    yield put({
      type: CREATE_OBJECTIVE_TYPE.FAIL,
      result: ["Unknow error"]
    });
  }
}

function* updateGroupType(obj) {
  const result = yield axiosPut(API.OBJECTIVE_TYPE.UPDATE.replace('{id}', obj.id), obj.data);

  if (result.success) {
    yield put({
      type: UPDATE_OBJECTIVE_TYPE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_OBJECTIVE_TYPE.FAIL,
      result
    });
  }
}
function* getGroupType(obj) {
  const result = yield axiosGet(API.OBJECTIVE_TYPE.SHOW.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: GET_OBJECTIVE_TYPE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_OBJECTIVE_TYPE.FAIL,
      result
    });
  }
}

function* deleteGroupType(obj) {
  const result = yield axiosDelete(API.OBJECTIVE_TYPE.DELETE.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: DELETE_OBJECTIVE_TYPE.SUCCESS
    });
  } else {
    yield put({
      type: DELETE_OBJECTIVE_TYPE.FAIL
    });
  }
}



export function* watchGroupTypes() {
  yield takeEvery(GET_OBJECTIVE_TYPES.REQUEST, getGroupTypes);
  yield takeEvery(CREATE_OBJECTIVE_TYPE.REQUEST, createGroupType);
  yield takeEvery(GET_OBJECTIVE_TYPE.REQUEST, getGroupType);
  yield takeEvery(DELETE_OBJECTIVE_TYPE.REQUEST, deleteGroupType);
  yield takeEvery(UPDATE_OBJECTIVE_TYPE.REQUEST, updateGroupType);
}

