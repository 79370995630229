import { createRequestTypes } from './utils';

export const GET_PLAN_TYPES = createRequestTypes('GET_PLAN_TYPES');
export const CREATE_PLAN_TYPE = createRequestTypes('CREATE_PLAN_TYPE');
export const UPDATE_PLAN_TYPE = createRequestTypes('UPDATE_PLAN_TYPE');
export const GET_PLAN_TYPE = createRequestTypes('GET_PLAN_TYPE');
export const DELETE_PLAN_TYPE = createRequestTypes('DELETE_PLAN_TYPE');

export const CREATE_OBJECTIVE = createRequestTypes('CREATE_OBJECTIVE');

export const CLEAN_PLAN_TYPE = 'CLEAN_PLAN_TYPE';
export const cleanPlanType = () => ({ type: CLEAN_PLAN_TYPE });

export const getPlanTypes = page => ({
  type: GET_PLAN_TYPES.REQUEST,
  page
});

export const createPlanType = data => ({
  type: CREATE_PLAN_TYPE.REQUEST,
  data
});

export const updatePlanType = (id, data) => ({
  type: UPDATE_PLAN_TYPE.REQUEST,
  id,
  data
});

export const getPlanType = id => ({
  type: GET_PLAN_TYPE.REQUEST,
  id
});

export const deletePlanType = id => ({
  type: DELETE_PLAN_TYPE.REQUEST,
  id
});
