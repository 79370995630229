import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_TASKS,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK
} from '../actions/task';
import { axiosGet, axiosPost, axiosPut, axiosDelete } from './utils';
import API from '../../configs/API';
import queryString from 'query-string';


function* getTasks(obj) {
  const result = yield axiosGet(API.TASK.LIST.replace(':activity_id', obj.activityId));
  if (result.success) {
    yield put({
      type: GET_TASKS.SUCCESS,
      result: result.data,
    });
  } else {
    yield put({
      type: GET_TASKS.FAIL,
      result: result.errorMessage,
    });
  }
}

function* createTask(obj) {
  const result = yield axiosPost(API.TASK.CREATE, obj.data);
  if (result.success) {
    yield put({
      type: CREATE_TASK.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: CREATE_TASK.FAIL,
      result: result.errorMessage.response.data.errors
    });
  }
}

function* updateTask(obj) {
  const result = yield axiosPut(API.TASK.UPDATE.replace('{id}', obj.id), obj.data);
  if (result.success) {
    yield put({
      type: UPDATE_TASK.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: UPDATE_TASK.FAIL,
      result: result.errorMessage.data.errors
    });
  }
}

function* deleteTask(obj) {
  const result = yield axiosDelete(API.TASK.DELETE.replace('{id}', obj.id));
  if (result.success) {
    yield put({
      type: DELETE_TASK.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: DELETE_TASK.FAIL,
      result,
    });
  }
}

export function* watchTask() {
  yield takeEvery(GET_TASKS.REQUEST, getTasks);
  yield takeEvery(CREATE_TASK.REQUEST, createTask);
  yield takeEvery(UPDATE_TASK.REQUEST, updateTask);
  yield takeEvery(DELETE_TASK.REQUEST, deleteTask);
}
