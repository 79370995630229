import { createRequestTypes } from './utils';

export const GET_TASK_NATURES = createRequestTypes('GET_TASK_NATURES');
export const CREATE_TASK_NATURE = createRequestTypes('CREATE_TASK_NATURE');
export const UPDATE_TASK_NATURE = createRequestTypes('UPDATE_TASK_NATURE');
export const GET_TASK_NATURE = createRequestTypes('GET_TASK_NATURE');
export const DELETE_TASK_NATURE = createRequestTypes('DELETE_TASK_NATURE');

export const CLEAN_TASK_NATURE = 'CLEAN_TASK_NATURE';
export const cleanTaskNature = () => ({ type: CLEAN_TASK_NATURE });

export const getTaskNatures = page => ({
  type: GET_TASK_NATURES.REQUEST,
  page
});

export const createTaskNature = data => ({
  type: CREATE_TASK_NATURE.REQUEST,
  data
});

export const updateTaskNature = (id, data) => ({
  type: UPDATE_TASK_NATURE.REQUEST,
  id,
  data
});

export const getTaskNature = id => ({
  type: GET_TASK_NATURE.REQUEST,
  id
});

export const deleteTaskNature = id => ({
  type: DELETE_TASK_NATURE.REQUEST,
  id
});
