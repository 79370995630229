import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_PLANS, CREATE_PLAN, GET_PLAN, DELETE_PLAN, CREATE_OBJECTIVE, UPDATE_PLAN
} from '../actions/plan';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosDelete, axiosPut } from './utils';
// import { sendMessage } from '../actions/conversations';

function* getPlans(obj) {
  const result = yield axiosGet(API.PLAN.LIST + '?page=' + obj.page);

  if (result.success) {
    yield put({
      type: GET_PLANS.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_PLANS.FAIL,
      result
    });
  }
}

function* createPlan(obj) {
  const result = yield axiosPost(API.PLAN.CREATE, obj.data);

  if (result.success) {
    yield put({
      type: CREATE_PLAN.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_PLANS.REQUEST,
      page: 1
    });
  } else if(result.success == false && result.errorMessage.response.status == 400) {
    yield put({
      type: CREATE_PLAN.FAIL,
      result: result.errorMessage.response.data.errors
    });
  }
  else {
    
    yield put({
      type: CREATE_PLAN.FAIL,
      result: ["Unknow error"]
    });
  }
}

function* updatePlan(obj) {
  const result = yield axiosPut(API.PLAN.UPDATE.replace('{id}', obj.id), obj.data);

  if (result.success) {
    yield put({
      type: UPDATE_PLAN.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_PLAN.FAIL,
      result
    });
  }
}
function* getPlan(obj) {
  const result = yield axiosGet(API.PLAN.SHOW.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: GET_PLAN.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_PLAN.FAIL,
      result
    });
  }
}

function* deletePlan(obj) {
  const result = yield axiosDelete(API.PLAN.DELETE.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: DELETE_PLAN.SUCCESS
    });
  } else {
    yield put({
      type: DELETE_PLAN.FAIL
    });
  }
}



export function* watchPlan() {
  yield takeEvery(GET_PLANS.REQUEST, getPlans);
  yield takeEvery(CREATE_PLAN.REQUEST, createPlan);
  yield takeEvery(GET_PLAN.REQUEST, getPlan);
  yield takeEvery(DELETE_PLAN.REQUEST, deletePlan);
  yield takeEvery(UPDATE_PLAN.REQUEST, updatePlan);
}

