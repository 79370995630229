import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ActivityDialog from '../components/Activity/ActivityDialog';
import ActivityList from '../components/Activity/ActivityList';
import ActivityDetail from '../components/Activity/ActivityDetail'
import { getPlans, getPlan, deletePlan, cleanPlan } from '../redux/actions/plan';
import { getOrgs } from '../redux/actions/org';
import { getActivities, getActivity } from '../redux/actions/activity';
import { getObjective } from '../redux/actions/objective';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

const queryString = require('query-string');

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    overflow: 'hidden',
    marginTop: 15,
    marginBottom: 15
  },
  grid: {
    display: 'flex',
    height: '100%',
  },
  modal: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  list: {
    overflowY: 'scroll',
    height: '100%',
    width: '100%'
  },
  text: {
    padding: 10,
    margin: 0,
    width: '100%'
  },
  planTitle: {
    lineHeight: '36px',
    verticalAlign: 'middle'
  },
  breadcrumb: {
    paddingBottom: 20
  },
  breadcrumbLink: {
    fontSize: '1rem',
    color: '#3f51b5'
  }
});
const handleClick = () => {
      
}
class Activity extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      currentPlan: null,
      addModal: false,
      planId: null,
      currentActivity: {}
    }
    
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.changeActivity = this.changeActivity.bind(this);
    this.refreshList = this.refreshList.bind(this);
  }

  componentDidMount() {
    document.title = "Hoạt động";

    const { match: { params } } = this.props;
    
    this.refreshList();

    this.changeActivity(params.id);
    this.props.getOrgs();
  }

  refreshList() {
    const { objective_id } = queryString.parse(this.props.location.search);

    if(objective_id) {
      this.props.getActivities(objective_id);
      this.props.getObjective(objective_id);
    }
  }

  changeActivity(id) {
    const { currentPlan } = this.props;
    
    this.setState({ 
      activityId: id
    }, () => {
      this.props.getActivity(id);
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { match: { params } } = this.props;
    

    if((prevProps.taskUpdated == null && this.props.taskUpdated == true) ||
    (prevProps.taskCreated == null && this.props.taskCreated == true) ||
    (prevProps.taskDeleted == null && this.props.taskDeleted == true)
    ) {
      this.changeActivity(params.id);
      
    }
    if((prevProps.activityUpdated == null && this.props.activityUpdated == true) ||
    (prevProps.activityCreated == null && this.props.activityCreated == true) ||
    (prevProps.activityDeleted == null && this.props.activityDeleted == true)) {
      this.refreshList();
    }
    
    if(prevProps.activityCreated == null && this.props.activityCreated == true) {
      this.setState({ 
        addModal: false
      })
    }

    if(params.id !== this.state.activityId) {
      this.changeActivity(params.id);
    }
  }


  toggleAddModal() {
    this.setState({addModal: !this.state.addModal})
  }

  render() {
    const { classes, currentActivity, currentPlan, currentObjective, activities } = this.props;
    // if (activitys.length > 0) {
    //   if (!this.props.match.params.id) {
    //     var activity = activitys[0]
    //     return <Redirect to={"/activity/" + activity.id + '?plan_id=' + activity.plan_id} />
    //   }

    //   var checkActivity = activitys.filter(p => {
    //     return p.id == this.props.match.params.id
    //   })[0]

    //   if (!checkActivity) {
    //     var activity = activitys.filter(p => {
    //       return p.id != this.props.match.params.id
    //     })[0]
    //     return <Redirect to={"/activity/" + activity.id + '?plan_id=' + activity.plan_id} />
    //   }
    // }
    
    
    return (
      <div>
        <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumb}>
          <Link component={RouterLink} color="inherit" to="/" className={classes.breadcrumbLink}>
            S-Planning
          </Link>
          <Link component={RouterLink} color="inherit" to={"/plan/" + currentPlan.id} className={classes.breadcrumbLink}>
            Kế hoạch: {currentPlan.title}
          </Link>
          <Link component={RouterLink}
            color="textPrimary"
            to={"/objective/" + currentActivity.id + '?plan_id=' + currentPlan.id}
            aria-current="page"
            className={classes.breadcrumbLink}
          >
            Mục tiêu: {currentObjective.title}
          </Link>
          <Link component={RouterLink}
            color="textPrimary"
            to={"/activity/" + currentActivity.id + '?objective_id=' + currentObjective.id}
            aria-current="page"
            className={classes.breadcrumbLink}
          >
            Hoạt động: {currentActivity.title}
          </Link>
        </Breadcrumbs>
          
        <Grid container spacing={24} className={classes.grid}>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" onClick={this.toggleAddModal}>Thêm hoạt động</Button>
            <Paper className={classes.paper} elevation={1}>
              <ActivityList />
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h5" className={classes.planTitle}>
              { currentActivity ? currentActivity.title : '' }
            </Typography>
            <Paper className={classes.paper} elevation={1}>
              <ActivityDetail data={currentPlan} refresh={() => this.changeActivity(this.state.planId)} />
            </Paper>
          </Grid>
        </Grid>
        {this.state.addModal && <ActivityDialog open={this.state.addModal} activity={{}} toggle={this.toggleAddModal} objective={currentObjective} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  plans: state.plan.plans,
  currentObjective: state.objective.currentObjective,
  currentPlan: state.plan.currentPlan,
  created: state.plan.created,
  deleted: state.plan.deleted,
  activityCreated: state.activity.create.success,
  activities: state.activity.data,
  currentActivity: state.activity.currentActivity,

  activityCreated: state.activity.create.success,
  activityUpdated: state.activity.update.success,
  activityDeleted: state.activity.delete.success,
  taskUpdated: state.task.update.success,
  taskCreated: state.task.create.success,
  taskDeleted: state.task.delete.success,
});
const mapDispatchToProps = {
  getPlans, getPlan, deletePlan, cleanPlan, getOrgs,
  getActivities, getActivity,
  getObjective
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Activity));