import { createRequestTypes } from './utils';

export const GET_ACTIVITY_TYPES = createRequestTypes('GET_ACTIVITY_TYPES');
export const CREATE_ACTIVITY_TYPE = createRequestTypes('CREATE_ACTIVITY_TYPE');
export const UPDATE_ACTIVITY_TYPE = createRequestTypes('UPDATE_ACTIVITY_TYPE');
export const GET_ACTIVITY_TYPE = createRequestTypes('GET_ACTIVITY_TYPE');
export const DELETE_ACTIVITY_TYPE = createRequestTypes('DELETE_ACTIVITY_TYPE');

export const CLEAN_ACTIVITY_TYPE = 'CLEAN_ACTIVITY_TYPE';
export const cleanActivityType = () => ({ type: CLEAN_ACTIVITY_TYPE });

export const getActivityTypes = page => ({
  type: GET_ACTIVITY_TYPES.REQUEST,
  page
});

export const createActivityType = data => ({
  type: CREATE_ACTIVITY_TYPE.REQUEST,
  data
});

export const updateActivityType = (id, data) => ({
  type: UPDATE_ACTIVITY_TYPE.REQUEST,
  id,
  data
});

export const getActivityType = id => ({
  type: GET_ACTIVITY_TYPE.REQUEST,
  id
});

export const deleteActivityType = id => ({
  type: DELETE_ACTIVITY_TYPE.REQUEST,
  id
});
