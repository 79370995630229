import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  root: {
    width: '100%'
  },
  item: {
    paddingLeft: 0,
    paddingRight: 0
  }
});

function ObjectiveList(props) {
  const { classes, objectives, isFetching } = props;
  
  // if(isFetching) {
  //   return <div>Loading</div>;
  // }

  return (
    
    <List className={classes.root}>
      {
        objectives && objectives.map(objective => 
          <ListItem component={Link} to={"/objective/" + objective.id + '?plan_id=' + objective.plan_id} divider className={classes.item} key={objective.id}>
            <ListItemText primary={objective.title} secondary={objective.description} />
          </ListItem>
        )
      }
    </List>
  );
}

ObjectiveList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  objectives: state.objective.data,
  isFetching: state.objective.isFetching
});
const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ObjectiveList));