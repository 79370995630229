import { combineReducers } from 'redux';
import login from './login';
import plan from './plan';
import task from './task';
import activity from './activity';
import objective from './objective';
import org from './org';
import plan_type from './plan_type';
import objective_type from './objective_type';
import objective_group from './objective_group';
import activity_type from './activity_type'
import task_type from './task_type';
import task_nature from './task_nature'

export default combineReducers({
  login, plan, task, activity, objective, org, plan_type, objective_type, objective_group, activity_type, task_type, task_nature
});
