import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import UILink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

class NavLink extends React.Component {
  render() {
    return (
      <Button color='inherit' component={Link} to={this.props.to}>
        {this.props.children}
      </Button>
    );
  }
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default NavLink;