import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import { createActivity, updateActivity } from '../../redux/actions/activity';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '../SnackBarContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
  paper: {

  }
})

class FormDialog extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      title: '',
      description: '',
      requirement: '',
      deadline: null,
      user: '',
      showError: false,
      types: []
    };

    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if(props.activity.id !== state.id) {
      return {
        id: props.activity.id,
        title: props.activity.title,
        description: props.activity.description,
        deadline: props.activity.deadline,
        requirement: props.activity.requirement,
        types: props.activity.types ? props.activity.types.map(x => x.id) : []
      }
    }
    return null;
  }

  onSubmit = () => {
    const data = {
      title: this.state.title,
      description: this.state.description,
      requirement: this.state.requirement,
      deadline: this.state.deadline,
      types: this.state.types,
      objective_id: this.props.objective.id
    };
    if(this.state.id) {
      this.props.updateActivity(this.state.id, data)
    }
    else {
      this.props.createActivity(data)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if((prevProps.successUpdate == null && this.props.successUpdate == false) ||
    (prevProps.successCreate == null && this.props.successCreate == false)) {
      this.setState({
        showError: true
      })
    }
  }

  onChangeType(ref) {
    if(ref.target.checked) {
      this.setState({
        types: [...this.state.types, ref.target.value]
      })
    }
    else {
      let types = this.state.types.filter(x => x !== ref.target.value);

      this.setState({
        types
      })
    }
  }

  handleCloseSnackbar() {
    this.setState({
      showError: false
    })
  }

  render() {
    const { open, toggle, types, classes } = this.props
    
    return (
      <Dialog
        open={open}
        onClose={toggle}
        aria-labelledby="form-dialog-title"
      >
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.showError}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <SnackbarContent
            onClose={this.handleCloseSnackbar}
            variant="error"
            message={this.props.errorsUpdate || this.props.errorsCreate}
          />
        </Snackbar>
        <DialogTitle id="form-dialog-title">{this.state.id ? 'Cập nhật hoạt động' : 'Thêm hoạt động'}</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            margin="dense"
            id="title"
            label="Tên hoạt động"
            type="text"
            fullWidth
            value={this.state.title || ''}
            onChange={ref => this.setState({ title: ref.target.value })}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="description"
            label="Mô tả"
            multiline
            rows={5}
            type="text"
            fullWidth
            value={this.state.description || ''}
            onChange={ref => this.setState({ description: ref.target.value })}
          />
          {/* <TextField
            variant="outlined"
            margin="dense"
            id="description"
            label="Người chủ trì (Nhập email)"
            
            type="text"
            fullWidth
            value={this.state.user || ''}
            onChange={ref => this.setState({ user: ref.target.value })}
          /> */}
          <TextField
            id="date"
            label="Thời hạn hoàn thành"
            type="date"
            fullWidth
            variant="outlined"
            value={this.state.deadline}
            onChange={ref => this.setState({ deadline: ref.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="requirement"
            label="Nội dung yêu cầu"
            multiline
            rows={5}
            type="text"
            fullWidth
            value={this.state.requirement || ''}
            onChange={ref => this.setState({ requirement: ref.target.value })}
          />
          <FormControl className={classes.formControl}>
            <FormLabel  htmlFor="age-native-helper">Loại hoạt động</FormLabel>
            <FormGroup>
              {types && types.map(type => 
                <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.types.find(x => x == type.id) !== undefined}
                    onChange={this.onChangeType}
                    value={type.id}
                  />
                }
                label={type.title}
              />
              )}
              
            </FormGroup>            
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle} color="default">
            Hủy bỏ
          </Button>
          <Button onClick={this.onSubmit} color="primary">
            {this.state.id ? 'Cập nhật' : 'Thêm hoạt động'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  errorsUpdate: state.activity.update.msg,
  successUpdate: state.activity.update.success,
  errorsCreate: state.activity.create.msg,
  successCreate: state.activity.create.success,
  types: state.activity_type.data
});
const mapDispatchToProps = {
  createActivity,
  updateActivity
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormDialog));