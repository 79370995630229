import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DeletePlanDialog from './DeletePlanDialog';
import DeleteObjectiveDialog from './Objective/DeleteObjectiveDialog';
import ObjectivesTable from './ObjectivesTable';
import TreeTable from './TreeTable';
import Button from '@material-ui/core/Button';
import ObjectiveDialog from './Objective/ObjectiveDialog';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import { deletePlan, updatePlan } from '../redux/actions/plan';
import { deleteObjective } from '../redux/actions/objective';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  content: {
    paddingTop: theme.spacing.unit * 2
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  formControl: {
    marginBottom: theme.spacing.unit * 3,
    minWidth: '400px'
  },
  textField: {
    marginBottom: theme.spacing.unit * 3,
    minWidth: '400px'
  }
});

class PlanDetail extends React.Component {
  state = {
    value: 0,
    addModal: false,
    deleteModal: false,
    deleteObjectiveModal: false,
    currentObjective: {},
    currentPlan: {}
  };

  constructor(props) {
    super(props);

    this.renderTabObjective = this.renderTabObjective.bind(this);
    this.renderTabInfo = this.renderTabInfo.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.refresh = this.refresh.bind(this);
    this.deleteObjective = this.deleteObjective.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  refresh() {
    this.setState({
      addModal: false
    });
    this.props.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.objectiveCreated == null && this.props.objectiveCreated == true) {
      this.refresh();
    }
    if(prevProps.objectiveUpdated == null && this.props.objectiveUpdated == true) {
      this.refresh();
    }
    if(prevProps.objectiveDeleted == null && this.props.objectiveDeleted == true) {
      this.refresh();
    }
    if(prevProps.planDeleted == null && this.props.planDeleted == true) {
      this.refresh();
    }
    if(prevProps.currentPlan.id !== this.props.currentPlan.id) {
      const { user } = this.props.currentPlan;
      this.setState({ currentPlan: {
        ...this.props.currentPlan, 
        user: user && user.email ? user.email : null
        } 
      });
    }
    if(prevProps.planUpdated == null && this.props.planUpdated == true) {
      this.refresh();
    }
  }

  onEdit(objective) {
    this.setState({
      addModal: true,
      currentObjective: objective
    })
  }

  onUpdate() {
    const { currentPlan } = this.state;
    this.props.updatePlan(currentPlan.id, {...currentPlan, organisation: currentPlan.organisation.id, type: currentPlan.type.id });
  }

  onDelete(objective) {
    this.setState({
      deleteObjectiveModal: true,
      currentObjective: objective
    })
  }

  onAdd() {
    this.setState({
      addModal: true,
      currentObjective: { id: null }
    })
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  toggleAddModal = () => {
    this.setState({addModal: !this.state.addModal})
  }

  deleteObjective(id) {
    this.setState({deleteObjectiveModal: false})
    this.props.deleteObjective(id);
  }

  renderTabObjective() {
    const { classes, currentPlan, objectives } = this.props;
    return (
      <div className={classes.content}>
        {/* <Button variant="contained" color="primary" onClick={this.onAdd}>Thêm mục tiêu</Button> */}
        <DeleteObjectiveDialog open={this.state.deleteObjectiveModal} handleClose={() => this.setState({ deleteObjectiveModal: false })} onDelete={(id) => this.deleteObjective(this.state.currentObjective.id)} />
        <TreeTable objectives={objectives} onEdit={this.onEdit} onDelete={this.onDelete} onAdd={this.onAdd} />
        {this.state.addModal && <ObjectiveDialog open={this.state.addModal} toggle={this.toggleAddModal} objective={this.state.currentObjective} plan={currentPlan} />}
      </div> 
    )
  }

  renderTabInfo() {
    const { classes, deletePlan, orgs, types } = this.props;
    const { currentPlan } = this.state;
    console.log(currentPlan)
    return (
      <div className={classes.content}>
        <TextField
            className={classes.textField}
            margin="dense"
            id="title"
            label="Tên kế hoạch"
            type="text"
            fullWidth
            value={currentPlan.title || ''}
            onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, title: ref.target.value } })}
          />
          <TextField
            className={classes.textField}
            margin="dense"
            id="description"
            label="Nội dung"
            multiline
            rows={5}
            type="text"
            fullWidth
            value={currentPlan.description || ''}
            onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, description: ref.target.value } })}
          />
          <TextField
            className={classes.textField}
            margin="dense"
            id="user"
            label="Người chủ trì (nhập email)"
            type="text"
            fullWidth
            value={currentPlan.user || ''}
            onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, description: ref.target.value } })}
          />
          <TextField
            className={classes.textField}
            margin="dense"
            id="support"
            label="Phối hợp"
            type="text"
            fullWidth
            value={currentPlan.support || ''}
            onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, support: ref.target.value } })}
          />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Đơn vị (bộ phận)</InputLabel>
            <NativeSelect
              value={currentPlan.organisation && currentPlan.organisation.id}
              onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, organisation: {id: ref.target.value } } })}
            >
            {orgs && orgs.map(org => [
              <option  value={org.id}>{org.title}</option >
              ,
              org.children && org.children.map(child => 
                <option  value={child.id}>---- {child.title}</option >)
            ]
            )}
            </NativeSelect>
          </FormControl>
          {/* <TextField
            margin="dense"
            id="user"
            label="Chủ trì"
            multiline
            type="text"
            fullWidth
            value={this.state.host}
            onChange={ref => this.setState({ host: ref.target.value })}
          /> */}
          <TextField
            className={classes.textField}
            id="date"
            label="Thời gian bắt đầu"
            type="date"
            fullWidth
            value={currentPlan.start_at}
            className={classes.textField}
            onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, start_at: ref.target.value } })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className={classes.textField}
            margin="dense"
            id="days"
            label="Số ngày thực hiện"
            multiline
            type="text"
            fullWidth
            value={currentPlan.days}
            onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, days: ref.target.value } })}

          />
          <TextField
            className={classes.textField}
            id="date"
            label="Thời gian kết thúc"
            type="date"
            fullWidth
            value={currentPlan.end_at}
            className={classes.textField}
            onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, end_at: ref.target.value } })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Loại kế hoạch</InputLabel>
            <Select
              value={currentPlan.type && currentPlan.type.id}
              onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, type: {id: ref.target.value } } })}
            >
            {types && types.map(type => 
              <MenuItem value={type.id}>{type.title}</MenuItem>
            )}
            </Select>
          </FormControl><br />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-helper">Tình trạng</InputLabel>
            <Select
              value={currentPlan.status}
              onChange={ref => this.setState({ currentPlan: { ...this.state.currentPlan, status: ref.target.value } })}
            >
            <MenuItem value='planning'>Đang lập kế hoạch</MenuItem>
            <MenuItem value='doing'>Đang tiến hành</MenuItem>
            <MenuItem value='done'>Đã hoàn thành</MenuItem>
            </Select>
          </FormControl><br />
        <Button variant="contained" size="small" color="primary" className={classes.button} onClick={this.onUpdate}>Lưu kế hoạch</Button>
        <Button variant="contained" size="small" color="secondary" className={classes.button} onClick={() => this.setState({ deleteModal: true }) }>Xóa kế hoạch</Button>
        <DeletePlanDialog open={this.state.deleteModal} handleClose={() => this.setState({ deleteModal: false })} onDelete={() => deletePlan(currentPlan.id)} />
      </div>
    )
  }

  render() {
    const { classes, currentPlan } = this.props;
    const { value } = this.state;
    
    return (
      currentPlan && <div className={classes.root}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Danh sách mục tiêu"
          />
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Thông tin kế hoạch"
          />
         
        </Tabs>
        { value === 0 && this.renderTabObjective() }
        { value === 1 && this.renderTabInfo() }
        
      </div>
    );
  }
}

PlanDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  currentPlan: state.plan.currentPlan,
  objectives: state.plan.objectives,
  objectiveCreated: state.objective.create.success,
  objectiveUpdated: state.objective.update.success,
  objectiveDeleted: state.objective.delete.success,
  planDeleted: state.plan.deleted,
  planUpdated: state.plan.updated,
  orgs: state.org.data,
  types: state.plan_type.data
});
const mapDispatchToProps = {
  deletePlan,
  deleteObjective,
  updatePlan
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlanDetail));