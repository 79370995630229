import {
  GET_TASKS,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK
} from '../actions/task';

const initialState = {
  isFetching: false,
  tasks: [],
  errorMessage: null,
  create: {
    isRequesting: false,
    success: null,
    msg: null,
  },
  update: {
    isRequesting: false,
    success: null,
    msg: null,
  },
  delete: {
    isRequesting: false,
    success: null,
    msg: null,
  }
};

export default (state = initialState, action) => {

  switch (action.type) {
    case GET_TASKS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GET_TASKS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        tasks: action.result.tasks
      };

    case GET_TASKS.FAIL:
      return {
        ...state,
        isFetching: false,
        tasks: [],
        errorMessage: action.result,
      };

    case CREATE_TASK.REQUEST:
      return {
        ...state,
        create: {
          isRequesting: true,
          success: null,
          msg: null,
        }
      };

    case CREATE_TASK.SUCCESS:
      return {
        ...state,
        create: {
          isRequesting: false,
          success: true,
          msg: action.result,
        }
      };

    case CREATE_TASK.FAIL:
      return {
        ...state,
        create: {
          isRequesting: false,
          success: false,
          msg: action.result,
        }
      };
      
    case UPDATE_TASK.REQUEST:
      return {
        ...state,
        update: {
          isRequesting: true,
          success: null,
          msg: null,
        }
      };

    case UPDATE_TASK.SUCCESS:
      return {
        ...state,
        update: {
          isRequesting: false,
          success: true,
          msg: action.result,
        }
      };

    case UPDATE_TASK.FAIL:
      return {
        ...state,
        update: {
          isRequesting: false,
          success: false,
          msg: action.result,
        }
      };
      
    case DELETE_TASK.REQUEST:
      return {
        ...state,
        delete: {
          isRequesting: true,
          success: null,
          msg: null,
        }
      };

    case DELETE_TASK.SUCCESS:
      return {
        ...state,
        delete: {
          isRequesting: false,
          success: true,
          msg: action.result,
        }
      };

    case DELETE_TASK.FAIL:
      return {
        ...state,
        delete: {
          isRequesting: false,
          success: false,
          msg: action.result,
        }
      };

    default:
      return state;
  }
};
