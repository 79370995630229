import { takeEvery, takeLatest, put } from 'redux-saga/effects';
import {
  GET_ORGS
} from '../actions/org';
import { axiosGet, axiosPost, axiosPut, axiosDelete } from './utils';
import API from '../../configs/API';
import queryString from 'query-string';


function* getOrgs(obj) {
  const result = yield axiosGet(API.ORG.LIST);
  
  if (result.success) {
    yield put({
      type: GET_ORGS.SUCCESS,
      result: result.data,
    });
  } else {
    yield put({
      type: GET_ORGS.FAIL,
      result: result.errorMessage,
    });
  }
}

export function* watchOrg() {
  yield takeLatest(GET_ORGS.REQUEST, getOrgs);
}
