import { takeEvery, put, select } from 'redux-saga/effects';
import {
  GET_OBJECTIVES,
  GET_OBJECTIVE,
  CREATE_OBJECTIVE,
  UPDATE_OBJECTIVE,
  DELETE_OBJECTIVE
} from '../actions/objective';
import { axiosGet, axiosPost, axiosPut, axiosDelete } from './utils';
import API from '../../configs/API';
import queryString from 'query-string';


function* getObjective(obj) {
  const result = yield axiosGet(API.OBJECTIVE.SHOW.replace('{id}', obj.id));
  
  if (result.success) {
    yield put({
      type: GET_OBJECTIVE.SUCCESS,
      result: result.data,
    });
  } else {
    yield put({
      type: GET_OBJECTIVE.FAIL,
      result: result.errorMessage,
    });
  }
}

function* getObjectives(obj) {
  const result = yield axiosGet(API.OBJECTIVE.LIST.replace(':plan_id', obj.planId));
  
  if (result.success) {
    yield put({
      type: GET_OBJECTIVES.SUCCESS,
      result: result.data,
    });
  } else {
    yield put({
      type: GET_OBJECTIVES.FAIL,
      result: result.errorMessage,
    });
  }
}

function* createObjective(obj) {
  const result = yield axiosPost(API.OBJECTIVE.CREATE, obj.data);
  
  if (result.success) {
    yield put({
      type: CREATE_OBJECTIVE.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_OBJECTIVES.REQUEST,
      planId: result.data.plan_id
    });
  } else {
    yield put({
      type: CREATE_OBJECTIVE.FAIL,
      result
    });
  }
}

function* updateObjective(obj) {
  const result = yield axiosPut(API.OBJECTIVE.UPDATE.replace('{id}', obj.id), obj.data);
  if (result.success) {
    yield put({
      type: UPDATE_OBJECTIVE.SUCCESS,
      result,
    });
    yield put({
      type: GET_OBJECTIVES.REQUEST,
      planId: result.data.plan_id,
    });
  } else {
    yield put({
      type: UPDATE_OBJECTIVE.FAIL,
      result,
    });
  }
}

function* deleteObjective(obj) {
  const result = yield axiosDelete(API.OBJECTIVE.DELETE.replace('{id}', obj.id));
  if (result.success) {
    yield put({
      type: DELETE_OBJECTIVE.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: DELETE_OBJECTIVE.FAIL,
      result,
    });
  }
}

export function* watchObjective() {
  yield takeEvery(GET_OBJECTIVES.REQUEST, getObjectives);
  yield takeEvery(GET_OBJECTIVE.REQUEST, getObjective);
  yield takeEvery(CREATE_OBJECTIVE.REQUEST, createObjective);
  yield takeEvery(UPDATE_OBJECTIVE.REQUEST, updateObjective);
  yield takeEvery(DELETE_OBJECTIVE.REQUEST, deleteObjective);
}
