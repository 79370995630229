import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_TASK_TYPES, CREATE_TASK_TYPE, GET_TASK_TYPE, DELETE_TASK_TYPE, UPDATE_TASK_TYPE
} from '../actions/task_type';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosDelete, axiosPut } from './utils';
// import { sendMessage } from '../actions/conversations';

function* getTaskTypes(obj) {
  const result = yield axiosGet(API.TASK_TYPE.LIST);

  if (result.success) {
    yield put({
      type: GET_TASK_TYPES.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_TASK_TYPES.FAIL,
      result
    });
  }
}

function* createTaskType(obj) {
  const result = yield axiosPost(API.TASK_TYPE.CREATE, obj.data);

  if (result.success) {
    yield put({
      type: CREATE_TASK_TYPE.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_TASK_TYPES.REQUEST,
      page: 1
    });
  } else if(result.success == false && result.errorMessage.response.status == 400) {
    yield put({
      type: CREATE_TASK_TYPE.FAIL,
      result: result.errorMessage.response.data.errors
    });
  }
  else {
    
    yield put({
      type: CREATE_TASK_TYPE.FAIL,
      result: ["Unknow error"]
    });
  }
}

function* updateTaskType(obj) {
  const result = yield axiosPut(API.TASK_TYPE.UPDATE.replace('{id}', obj.id), obj.data);

  if (result.success) {
    yield put({
      type: UPDATE_TASK_TYPE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_TASK_TYPE.FAIL,
      result
    });
  }
}
function* getTaskType(obj) {
  const result = yield axiosGet(API.TASK_TYPE.SHOW.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: GET_TASK_TYPE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_TASK_TYPE.FAIL,
      result
    });
  }
}

function* deleteTaskType(obj) {
  const result = yield axiosDelete(API.TASK_TYPE.DELETE.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: DELETE_TASK_TYPE.SUCCESS
    });
  } else {
    yield put({
      type: DELETE_TASK_TYPE.FAIL
    });
  }
}



export function* watchTaskTypes() {
  yield takeEvery(GET_TASK_TYPES.REQUEST, getTaskTypes);
  yield takeEvery(CREATE_TASK_TYPE.REQUEST, createTaskType);
  yield takeEvery(GET_TASK_TYPE.REQUEST, getTaskType);
  yield takeEvery(DELETE_TASK_TYPE.REQUEST, deleteTaskType);
  yield takeEvery(UPDATE_TASK_TYPE.REQUEST, updateTaskType);
}

