import {
  GET_OBJECTIVES,
  GET_OBJECTIVE,
  CREATE_OBJECTIVE,
  UPDATE_OBJECTIVE,
  DELETE_OBJECTIVE
} from '../actions/objective';

const initialState = {
  isFetching: false,
  data: [],
  errorMessage: null,
  currentObjective: {},
  create: {
    isRequesting: false,
    success: null,
    msg: null,
  },
  update: {
    isRequesting: false,
    success: null,
    msg: null,
  },
  delete: {
    isRequesting: false,
    success: null,
    msg: null,
  }
};

export default (state = initialState, action) => {

  switch (action.type) {
    case GET_OBJECTIVES.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GET_OBJECTIVES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.result.objectives
      };

    case GET_OBJECTIVES.FAIL:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.result,
      };
    
    case GET_OBJECTIVE.REQUEST:
      return {
        ...state,
        currentObjective: {},
        isFetching: true,
      };

    case GET_OBJECTIVE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentObjective: action.result
      };

    case GET_OBJECTIVE.FAIL:
      return {
        ...state,
        isFetching: false,
        currentObjective: {},
        errorMessage: action.result,
      };

    case CREATE_OBJECTIVE.REQUEST:
      return {
        ...state,
        create: {
          isRequesting: true,
          success: null,
          msg: null,
        }
      };

    case CREATE_OBJECTIVE.SUCCESS:
      return {
        ...state,
        create: {
          isRequesting: false,
          success: true,
          msg: action.result,
        }
      };

    case CREATE_OBJECTIVE.FAIL:
      return {
        ...state,
        create: {
          isRequesting: false,
          success: false,
          msg: action.result,
        }
      };
      
    case UPDATE_OBJECTIVE.REQUEST:
      return {
        ...state,
        update: {
          isRequesting: true,
          success: null,
          msg: null,
        }
      };

    case UPDATE_OBJECTIVE.SUCCESS:
      return {
        ...state,
        update: {
          isRequesting: false,
          success: true,
          msg: action.result,
        }
      };

    case UPDATE_OBJECTIVE.FAIL:
      return {
        ...state,
        update: {
          isRequesting: false,
          success: false,
          msg: action.result,
        }
      };
      
    case DELETE_OBJECTIVE.REQUEST:
      return {
        ...state,
        delete: {
          isRequesting: true,
          success: null,
          msg: null,
        }
      };

    case DELETE_OBJECTIVE.SUCCESS:
      return {
        ...state,
        delete: {
          isRequesting: false,
          success: true,
          msg: action.result,
        }
      };

    case DELETE_OBJECTIVE.FAIL:
      return {
        ...state,
        delete: {
          isRequesting: false,
          success: false,
          msg: action.result,
        }
      };

    default:
      return state;
  }
};
