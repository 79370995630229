import {
  GET_PLANS, GET_PLAN, DELETE_PLAN, CREATE_PLAN, CLEAN_PLAN, CREATE_OBJECTIVE, UPDATE_PLAN
} from '../actions/plan';

const initialState = {
  plans: [],
  currentPlan: {},
  objectives: [],
  plansFetching: false,
  planFetching: false,
  created: false,
  deleted: null,
  updated: null,
  objectiveCreated: false,
  errors: []
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS.REQUEST:
      return {
        ...state,
        plansFetching: true
      };

    case GET_PLANS.SUCCESS:
      if (action.result.currentPage > state.currentPage) {
        return {
          ...state,
          plansFetching: false,
          planFetching: false,
          created: false,
          deleted: false,
          currentPage: action.result.currentPage,
          totalPages: action.result.totalPages,
          plans: state.plans.concat(action.result.plans)
        };
      }
      return {
        ...state,
        currentPage: action.result.currentPage,
        totalPages: action.result.totalPages,
        plansFetching: false,
        planFetching: false,
        created: false,
        deleted: false,
        plans: action.result.plans
      };

    case GET_PLANS.FAIL:
      return {
        ...state,
        plansFetching: false
      };

    case CREATE_PLAN.REQUEST:
      return {
        ...state,
        created: null
      };

    case CREATE_PLAN.SUCCESS:
      return {
        ...state,
        created: true
      };

    case CREATE_PLAN.FAIL:
      return {
        ...state,
        created: false,
        errors: action.result
      };

    case UPDATE_PLAN.REQUEST:
      return {
        ...state,
        updated: null
      };

    case UPDATE_PLAN.SUCCESS:
      return {
        ...state,
        updated: true
      };

    case UPDATE_PLAN.FAIL:
      return {
        ...state,
        updated: false
      };

    case GET_PLAN.REQUEST:
      return {
        ...state,
        planFetching: true
      };

    case GET_PLAN.SUCCESS:
      return {
        ...state,
        currentPlan: action.result.plan,
        objectives: action.result.objectives,
        plansFetching: false
      };
    
    case DELETE_PLAN.REQUEST:
      return {
        ...state,
        deleted: null
      };
    case DELETE_PLAN.SUCCESS:
      return {
        ...state,
        deleted: true
      };
    
      case DELETE_PLAN.FAIL:
      return {
        ...state,
        deleted: false
      };
    

    case CLEAN_PLAN:
      return {
        ...state,
        deleted: false
      };

    default:
      return state;
  }
}