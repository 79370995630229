import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DeleteObjectiveDialog from '../Objective/DeleteObjectiveDialog';
import DeleteActivityDialog from '../Activity/DeleteActivityDialog';
import TaskTable from '../Task/TaskTable';
import TaskRow from '../Task/TaskRow';
import Button from '@material-ui/core/Button';
import ActivityDialog from '../Activity/ActivityDialog';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import queryString from 'query-string'
import { deletePlan, updatePlan } from '../../redux/actions/plan';
import { deleteObjective, getObjective } from '../../redux/actions/objective';
import { deleteActivity, updateActivity, getActivities } from '../../redux/actions/activity'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  content: {
    paddingTop: theme.spacing.unit * 2
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  formControl: {
    marginBottom: theme.spacing.unit * 3,

  }
});

class ObjectiveDetail extends React.Component {
  state = {
    value: 0,
    addModal: false,
    deleteModal: false,
    deleteActivityModal: false,
    currentActivity: {}
  };

  constructor(props) {
    super(props);

    this.renderTabActivity = this.renderTabActivity.bind(this);
    this.renderTabInfo = this.renderTabInfo.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.refresh = this.refresh.bind(this);
    this.deleteObjective = this.deleteObjective.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  refresh() {
    if(this.props.currentActivity) {
      this.props.getActivities(this.props.currentActivity.objective.id);
    }
    this.setState({
      addModal: false,
      deleteActivityModal: false
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.currentActivity && prevProps.currentActivity.id !== this.props.currentActivity.id) {
      this.setState({ currentActivity: this.props.currentActivity });
    }
    if(prevProps.activityDeleted == null && this.props.activityDeleted == true) {
      this.setState({
        deleteModal: false
      })
    }
  }

  onEdit(activity) {
    this.setState({
      addModal: true,
      currentActivity: activity
    })
  }

  onUpdate() {
    const { currentActivity } = this.state;
    this.props.updateActivity(currentActivity.id, this.state.currentActivity);
  }

  onDelete(activity) {
    this.setState({
      deleteActivityModal: true,
      currentActivity: activity
    })
  }

  onAdd() {
    this.setState({
      addModal: true,
      currentActivity: {}
    })
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  toggleAddModal = () => {
    this.setState({addModal: !this.state.addModal})
  }

  deleteObjective(id) {
    this.setState({deleteActivityModal: false})
    this.props.deleteObjective(id);
  }

  renderTabActivity() {
    const { classes, currentPlan, currentActivity } = this.props;
    if(!currentActivity)
      return null;
    return (
      <div className={classes.content}>
        {/* <Button variant="contained" color="primary" onClick={this.onAdd}>Thêm hoạt động</Button> */}
        
        {/* <TaskTable tasks={currentActivity.tasks} onEdit={this.onEdit} onDelete={this.onDelete} /> */}
        <TaskTable activity={currentActivity} />
        {/* {this.state.addModal && <ActivityDialog open={this.state.addModal} toggle={this.toggleAddModal} activity={this.state.currentActivity} objective={currentActivity} />} */}
      </div> 
    )
  }

  renderTabInfo() {
    const { classes, deleteActivity } = this.props;
    const { currentActivity } = this.state;
    if(!currentActivity)
      return null;
    return (
      <div className={classes.content}>
        <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Tên mục tiêu"
            type="text"
            fullWidth
            value={currentActivity.title || ''}
            onChange={ref => this.setState({ currentActivity: { ...this.state.currentActivity, title: ref.target.value } })}
          />
          <TextField
            margin="dense"
            id="description"
            label="Nội dung"
            multiline
            rows={5}
            type="text"
            fullWidth
            value={currentActivity.description || ''}
            onChange={ref => this.setState({ currentActivity: { ...this.state.currentActivity, description: ref.target.value } })}
          />
          
        
        <Button variant="contained" color="primary" className={classes.button} onClick={this.onUpdate}>Lưu hoạt động</Button>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => this.setState({ deleteModal: true }) }>Xóa hoạt động</Button>
        <DeleteActivityDialog open={this.state.deleteModal} handleClose={() => this.setState({ deleteModal: false })} onDelete={() => deleteActivity(currentActivity.id)} />
      </div>
    )
  }

  render() {
    const { classes, currentActivity } = this.props;
    const { value } = this.state;
    
    return (
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Danh sách công việc"
          />
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Thông tin hoạt động"
          />
          
        </Tabs>
        { value === 0 && this.renderTabActivity() }
        { value === 1 && this.renderTabInfo() }
      </div>
    );
  }
}

ObjectiveDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  currentActivity: state.activity.currentActivity,
  objectives: state.plan.objectives,
  activityDeleted: state.activity.delete.success,
  orgs: state.org.data,
});
const mapDispatchToProps = {
  deleteObjective,
  updateActivity,
  getObjective,
  deleteActivity,
  getActivities
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ObjectiveDetail));