import {
  LOGIN,
  RECOVER,
  REGISTER,
  LOGIN_FACEBOOK,
  LOGIN_GOOGLE,
  LOGOUT
} from '../actions/login';
import axios from 'axios';

const initialState = {
  isLogged: false,
  isRequestLoging: false,
  success: false,
  token: null,
  user: null,
  userId: 0,
  errorMessage: null,

  isRequestRecovering: false,
  recoverSuccess: false,

  isRegistering: false,
  registerSuccess: false,
  registerResult: null
};

export default (state = initialState, action) => {

  switch (action.type) {
    case LOGOUT:
      axios.defaults.headers.common.Authorization = null;
      return initialState;

    case REGISTER.REQUEST:
      return {
        ...state,
        isRegistering: true,
        registerResult: action.result,
        registerSuccess: false
      };

    case REGISTER.SUCCESS:
      return {
        ...state,
        isRegistering: false,
        registerResult: action.result,
        registerSuccess: true,
        token: action.result.token,
        user: action.result.user,
        userId: action.result.user.id
      };

    case REGISTER.FAIL:
      return {
        ...state,
        isRegistering: false,
        registerResult: action.result,
        registerSuccess: false,
        errorMessage: action.result.message
      };

    case LOGIN_FACEBOOK:
      return { ...state, isRequestLoging: true, isLogged: false };

    case LOGIN_GOOGLE:
      return { ...state, isRequestLoging: true, isLogged: false };

    case LOGIN.REQUEST:
      return { ...state, isRequestLoging: true, isLogged: false };

    case LOGIN.SUCCESS:
      console.log(action.result)
      axios.defaults.headers.common.Authorization = 'Bearer ' + action.result.token;
      return {
        ...state,
        isLogged: true,
        isRequestLoging: false,
        success: true,
        token: action.result.token,
        user: action.result.user,
        userId: action.result.user.id
      };

    case LOGIN.FAIL:
      return {
        ...state,
        isLogged: false,
        isRequestLoging: false,
        success: false,
        errorMessage: action.result.message
      };

    case RECOVER.REQUEST:
      return { ...state, isRequestRecovering: true, recoverSuccess: false };

    case RECOVER.FAIL:
      return { ...state, isRequestRecovering: false, recoverSuccess: false };

    case RECOVER.SUCCESS:
      return { ...state, isRequestRecovering: false, recoverSuccess: true };

    default:
      return state;
  }
};
