import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  root: {
    width: '100%'
  },
  item: {
    paddingLeft: 0,
    paddingRight: 0
  }
});

function ActivityList(props) {
  const { classes, activities, isFetching } = props;
  
  // if(isFetching) {
  //   return <div>Loading</div>;
  // }

  return (
    
    <List className={classes.root}>
      {
        activities && activities.map(activity => 
          <ListItem component={Link} to={"/activity/" + activity.id + '?objective_id=' + activity.objective.id} divider className={classes.item} key={activity.id}>
            <ListItemText primary={activity.title} secondary={activity.description} />
          </ListItem>
        )
      }
    </List>
  );
}

ActivityList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activities: state.activity.data,
  isFetching: state.activity.isFetching
});
const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ActivityList));