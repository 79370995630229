import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { watchLogin } from './login';
import { watchPlan } from './plan';
import { watchTask } from './task';
import { watchActivity } from './activity';
import { watchObjective } from './objective';
import { watchOrg } from './org';
import { watchPlanTypes } from './plan_type';
import { watchGroupTypes } from './objective_type';
import { watchObjectiveGroups } from './objective_group';
import { watchActivityTypes } from './activity_type';
import { watchTaskTypes } from './task_type';
import { watchTaskNatures } from './task_nature'

export default function* sagas() {
  yield all([
    fork(watchLogin),
    fork(watchPlan),
    fork(watchTask),
    fork(watchActivity),
    fork(watchObjective),
    fork(watchOrg),
    fork(watchPlanTypes),
    fork(watchGroupTypes),
    fork(watchObjectiveGroups),
    fork(watchActivityTypes),
    fork(watchTaskTypes),
    fork(watchTaskNatures)
  ]);
}
