import { createRequestTypes } from './utils';

export const GET_PLANS = createRequestTypes('GET_PLANS');
export const CREATE_PLAN = createRequestTypes('CREATE_PLAN');
export const UPDATE_PLAN = createRequestTypes('UPDATE_PLAN');
export const GET_PLAN = createRequestTypes('GET_PLAN');
export const DELETE_PLAN = createRequestTypes('DELETE_PLAN');

export const CREATE_OBJECTIVE = createRequestTypes('CREATE_OBJECTIVE');

export const CLEAN_PLAN = 'CLEAN_PLAN';
export const cleanPlan = () => ({ type: CLEAN_PLAN });

export const getPlans = page => ({
  type: GET_PLANS.REQUEST,
  page
});

export const createPlan = data => ({
  type: CREATE_PLAN.REQUEST,
  data
});

export const updatePlan = (id, data) => ({
  type: UPDATE_PLAN.REQUEST,
  id,
  data
});

export const getPlan = id => ({
  type: GET_PLAN.REQUEST,
  id
});

export const deletePlan = id => ({
  type: DELETE_PLAN.REQUEST,
  id
});
