import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import MULink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

class TextLink extends React.Component {
  render() {
    return (
      <MULink component={Link} color='primary' to={this.props.to}>
        <Typography color='primary'>{this.props.children}</Typography>
      </MULink>
    );
  }
}

TextLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default TextLink;