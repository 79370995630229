import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_ACTIVITIES,
  GET_ACTIVITY,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY
} from '../actions/activity';
import { axiosGet, axiosPost, axiosPut, axiosDelete } from './utils';
import API from '../../configs/API';
import queryString from 'query-string';


function* getActivities(obj) {
  const result = yield axiosGet(API.ACTIVITY.LIST.replace(':obj_id', obj.objectiveId));
  
  if (result.success) {
    yield put({
      type: GET_ACTIVITIES.SUCCESS,
      result: result.data,
    });
  } else {
    yield put({
      type: GET_ACTIVITIES.FAIL,
      result: result.errorMessage,
    });
  }
}

function* getActivity(obj) {
  const result = yield axiosGet(API.ACTIVITY.SHOW.replace('{id}', obj.id));
  
  if (result.success) {
    yield put({
      type: GET_ACTIVITY.SUCCESS,
      result: result.data,
    });
  } else {
    yield put({
      type: GET_ACTIVITY.FAIL,
      result: result.errorMessage,
    });
  }
}
function* createActivity(obj) {
  const result = yield axiosPost(API.ACTIVITY.CREATE, obj.data);
  
  if (result.success) {
    yield put({
      type: CREATE_ACTIVITY.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: CREATE_ACTIVITY.FAIL,
      result: result.errorMessage.response.data.errors
    });
  }
}

function* updateActivity(obj) {
  const result = yield axiosPut(API.ACTIVITY.UPDATE.replace('{id}', obj.id), obj.data);
  
  if (result.success) {
    yield put({
      type: UPDATE_ACTIVITY.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: UPDATE_ACTIVITY.FAIL,
      result: result.errorMessage.data.errors
    });
  }
}

function* deleteActivity(obj) {
  const result = yield axiosDelete(API.ACTIVITY.DELETE.replace('{id}', obj.id));
  if (result.success) {
    yield put({
      type: DELETE_ACTIVITY.SUCCESS,
      result,
    });
  } else {
    yield put({
      type: DELETE_ACTIVITY.FAIL,
      result,
    });
  }
}

export function* watchActivity() {
  yield takeEvery(GET_ACTIVITIES.REQUEST, getActivities);
  yield takeEvery(GET_ACTIVITY.REQUEST, getActivity);
  yield takeEvery(CREATE_ACTIVITY.REQUEST, createActivity);
  yield takeEvery(UPDATE_ACTIVITY.REQUEST, updateActivity);
  yield takeEvery(DELETE_ACTIVITY.REQUEST, deleteActivity);
}
