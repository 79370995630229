import { createRequestTypes } from './utils';

export const GET_OBJECTIVE_GROUPS = createRequestTypes('GET_OBJECTIVE_GROUPS');
export const CREATE_OBJECTIVE_GROUP = createRequestTypes('CREATE_OBJECTIVE_GROUP');
export const UPDATE_OBJECTIVE_GROUP = createRequestTypes('UPDATE_OBJECTIVE_GROUP');
export const GET_OBJECTIVE_GROUP = createRequestTypes('GET_OBJECTIVE_GROUP');
export const DELETE_OBJECTIVE_GROUP = createRequestTypes('DELETE_OBJECTIVE_GROUP');

export const CLEAN_OBJECTIVE_GROUP = 'CLEAN_OBJECTIVE_GROUP';
export const cleanObjectiveGroup = () => ({ type: CLEAN_OBJECTIVE_GROUP });

export const getObjectiveGroups = page => ({
  type: GET_OBJECTIVE_GROUPS.REQUEST,
  page
});

export const createObjectiveGroup = data => ({
  type: CREATE_OBJECTIVE_GROUP.REQUEST,
  data
});

export const updateObjectiveGroup = (id, data) => ({
  type: UPDATE_OBJECTIVE_GROUP.REQUEST,
  id,
  data
});

export const getObjectiveGroup = id => ({
  type: GET_OBJECTIVE_GROUP.REQUEST,
  id
});

export const deleteObjectiveGroup = id => ({
  type: DELETE_OBJECTIVE_GROUP.REQUEST,
  id
});
