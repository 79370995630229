import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_ACTIVITY_TYPES, CREATE_ACTIVITY_TYPE, GET_ACTIVITY_TYPE, DELETE_ACTIVITY_TYPE, UPDATE_ACTIVITY_TYPE
} from '../actions/activity_type';
import API from '../../configs/API';
import { axiosGet, axiosPost, axiosDelete, axiosPut } from './utils';
// import { sendMessage } from '../actions/conversations';

function* getActivityTypes(obj) {
  const result = yield axiosGet(API.ACTIVITY_TYPE.LIST);

  if (result.success) {
    yield put({
      type: GET_ACTIVITY_TYPES.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_ACTIVITY_TYPES.FAIL,
      result
    });
  }
}

function* createActivityType(obj) {
  const result = yield axiosPost(API.ACTIVITY_TYPE.CREATE, obj.data);

  if (result.success) {
    yield put({
      type: CREATE_ACTIVITY_TYPE.SUCCESS,
      result: result.data
    });
    yield put({
      type: GET_ACTIVITY_TYPES.REQUEST,
      page: 1
    });
  } else if(result.success == false && result.errorMessage.response.status == 400) {
    yield put({
      type: CREATE_ACTIVITY_TYPE.FAIL,
      result: result.errorMessage.response.data.errors
    });
  }
  else {
    
    yield put({
      type: CREATE_ACTIVITY_TYPE.FAIL,
      result: ["Unknow error"]
    });
  }
}

function* updateActivityType(obj) {
  const result = yield axiosPut(API.ACTIVITY_TYPE.UPDATE.replace('{id}', obj.id), obj.data);

  if (result.success) {
    yield put({
      type: UPDATE_ACTIVITY_TYPE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: UPDATE_ACTIVITY_TYPE.FAIL,
      result
    });
  }
}
function* getActivityType(obj) {
  const result = yield axiosGet(API.ACTIVITY_TYPE.SHOW.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: GET_ACTIVITY_TYPE.SUCCESS,
      result: result.data
    });
  } else {
    yield put({
      type: GET_ACTIVITY_TYPE.FAIL,
      result
    });
  }
}

function* deleteActivityType(obj) {
  const result = yield axiosDelete(API.ACTIVITY_TYPE.DELETE.replace('{id}', obj.id));

  if (result.success) {
    yield put({
      type: DELETE_ACTIVITY_TYPE.SUCCESS
    });
  } else {
    yield put({
      type: DELETE_ACTIVITY_TYPE.FAIL
    });
  }
}



export function* watchActivityTypes() {
  yield takeEvery(GET_ACTIVITY_TYPES.REQUEST, getActivityTypes);
  yield takeEvery(CREATE_ACTIVITY_TYPE.REQUEST, createActivityType);
  yield takeEvery(GET_ACTIVITY_TYPE.REQUEST, getActivityType);
  yield takeEvery(DELETE_ACTIVITY_TYPE.REQUEST, deleteActivityType);
  yield takeEvery(UPDATE_ACTIVITY_TYPE.REQUEST, updateActivityType);
}

