import {
  GET_ORGS
} from '../actions/org';

const initialState = {
  isFetching: false,
  data: [],
  errorMessage: null,
  create: {
    isRequesting: false,
    success: null,
    msg: null,
  },
  update: {
    isRequesting: false,
    success: null,
    msg: null,
  },
  delete: {
    isRequesting: false,
    success: null,
    msg: null,
  }
};

export default (state = initialState, action) => {

  switch (action.type) {
    case GET_ORGS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GET_ORGS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.result.orgs
      };

    case GET_ORGS.FAIL:
      return {
        ...state,
        isFetching: false,
        data: [],
        errorMessage: action.result,
      };

    default:
      return state;
  }
};
