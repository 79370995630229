import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import ActivityDetail from '../Activity/ActivityDetail';
const styles = theme => ({
  table: {
    minWidth: 700,
  },
  button: {
    marginLeft: theme.spacing.unit,
  }
});

function SimpleTable(props) {
  const { classes, activities } = props;

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Tên hoạt động</TableCell>
          <TableCell>Mô tả</TableCell>
          <TableCell>Nội dung yêu cầu</TableCell>
          <TableCell>Loại hoạt động</TableCell>
          <TableCell>Thời hạn hoàn thành</TableCell>
          <TableCell align="right">Tác vụ</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activities && activities.map(activity => (
          <TableRow key={activity.id}>
            <TableCell component="th" scope="row">
              <Link component={RouterLink} to={'/activity/' + activity.id + '?objective_id=' + activity.objective.id}>{activity.title}</Link>
            </TableCell>
            <TableCell scope="row">
              {activity.description}
            </TableCell>
            <TableCell scope="row">
              {activity.requirement}
            </TableCell>
            <TableCell scope="row">
              {activity.types && activity.types.map(type =>
              <p>{type.title}</p>  
              )}
            </TableCell>
            <TableCell scope="row">
              {activity.deadline}
            </TableCell>
            <TableCell align="right">
              <Button size="small" color="primary" className={classes.button} onClick={() => props.onEdit(activity)}>
                <EditIcon className={classes.rightIcon}/>
              </Button>
              <Button size="small" color="secondary" className={classes.button} onClick={() => props.onDelete(activity)}>
                <DeleteIcon className={classes.rightIcon} />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);