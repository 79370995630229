import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    overflow: 'hidden',
    marginTop: 15,
    marginBottom: 15
  },
  grid: {
    display: 'flex',
    height: '100%',
  },
  breadcrumb: {
    paddingBottom: 20
  },
  breadcrumbLink: {
    fontSize: '1rem',
    color: '#3f51b5'
  }
})

class Orgs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumb}>
          <Link component={RouterLink} color="inherit" to="/" className={classes.breadcrumbLink}>
            S-Planning
          </Link>
        </Breadcrumbs>
      </div>
    )
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Orgs));