import { createRequestTypes } from './utils';

export const GET_TASKS = createRequestTypes('GET_TASKS');
export const CREATE_TASK = createRequestTypes('CREATE_TASK');
export const UPDATE_TASK = createRequestTypes('UPDATE_TASK');
export const DELETE_TASK = createRequestTypes('DELETE_TASK');

export const getTasks = (activityId) => ({ type: GET_TASKS.REQUEST, activityId });
export const createTask = data => ({ type: CREATE_TASK.REQUEST, data })
export const updateTask = (id, data) => ({ type: UPDATE_TASK.REQUEST, id, data })
export const deleteTask = id => ({ type: DELETE_TASK.REQUEST, id });