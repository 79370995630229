import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

const styles = theme => ({
  table: {
    minWidth: 700,
  },
  button: {
    marginLeft: theme.spacing.unit,
  }
});

function SimpleTable(props) {
  const { classes, objectives } = props;

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Tên mục tiêu</TableCell>
          <TableCell>Giới thiệu</TableCell>
          <TableCell align="right">Tác vụ</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {objectives.map(objective => (
          <TableRow key={objective.id}>
            <TableCell component="th" scope="row">
              <Link to={'/objective/' + objective.id + '?plan_id=' + objective.plan_id}>
                <strong>{objective.title}</strong>
              </Link>
            </TableCell>
            <TableCell scope="row">
              {objective.description}
            </TableCell>
            <TableCell align="right">
              <Button variant="contained" color="primary" className={classes.button} onClick={() => props.onEdit(objective)}>
                <EditIcon className={classes.rightIcon}/>
              </Button>
              <Button variant="contained" color="secondary" className={classes.button} onClick={() => props.onDelete(objective)}>
                <DeleteIcon className={classes.rightIcon} />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);