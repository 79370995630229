import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '../components/SnackBarContent';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { login } from '../redux/actions/login';
import { loginGoogle, loginFacebook } from '../redux/actions/login'
import { Divider } from '@material-ui/core';
import TextLink from '../components/TextLink'
//import logo from '../images/logo.png'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  logo: {
    height: 50,
    marginTop: 30,
    marginBottom: 30
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class SignIn extends Component {

  state = {
    email: null,
    password: null,
    open: false,
    messageError: null
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isRequestLoging === true && this.props.isRequestLoging !== prevProps.isRequestLoging) {
      if(this.props.errorMessage != null) {
        this.setState({
          open: true,
          messageError: this.props.errorMessage.data.message
        })
      }
    }
  }

  // loginGoogle = () => {
  //   var provider = new firebase.auth.GoogleAuthProvider();

  //   firebaseApp.auth().signInWithPopup(provider).then(result => {
  //     this.props.loginGoogle(result.credential.accessToken);
  //   }).catch(error => {
  //     this.setState({
  //       open: true,
  //       messageError: "Có lỗi xảy ra, không thể đăng nhập! Error Code: " + error.code
  //     })
  //   });
  // }

  // loginFacebook = () => {
  //   var provider = new firebase.auth.FacebookAuthProvider();

  //   firebaseApp.auth().signInWithPopup(provider).then(result => {
  //     this.props.loginFacebook(result.credential.accessToken);
  //   }).catch(error => {
  //     this.setState({
  //       open: true,
  //       messageError: "Có lỗi xảy ra, không thể đăng nhập! Error Code: " + error.code
  //     })

  //   });
  // }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  componentDidMount() {
    document.title = "Đăng nhập"
  }

  render() {
    const { classes } = this.props;

    if(this.props.userId) {
      return <Redirect
        to={{
          pathname: "/",
        }}
        push 
      />
    }
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <img className={classes.logo} src="/logo.png" />
          <Typography component="h1" variant="h5">
            S-Planning
          </Typography>
          <form className={classes.form}>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <SnackbarContent
              onClose={this.handleClose}
              variant="error"
              message={this.state.messageError}
            />
          </Snackbar>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input id="email" name="email" autoComplete="email" autoFocus onChange={ref => this.setState({ email: ref.target.value })} />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Mật khẩu</InputLabel>
              <Input name="password" type="password" id="password" autoComplete="current-password" onChange={ref => this.setState({ password: ref.target.value })} />
            </FormControl>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => this.props.login(this.state.email, this.state.password)}
            >
              Đăng nhập
            </Button>
            
            {/* <FormControl margin="normal" fullWidth>
              <Button variant="contained" color="secondary" className={classes.button} onClick={this.loginGoogle}>
                Sign in With Google
              </Button>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Button variant="contained" color="primary" className={classes.button} onClick={this.loginFacebook}>
                Sign in With Facebook
              </Button>
            </FormControl> */}
          </form>
        </Paper>
      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userId: state.login.userId,
  errorMessage: state.login.errorMessage,
  isRequestLoging: state.login.isRequestLoging
});
const mapDispatchToProps = {
  login,
  loginGoogle,
  loginFacebook
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignIn));